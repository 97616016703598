import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Typography,
} from "@material-tailwind/react";
import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dayName } from "../../extraStuff";
import { getRequest } from "../../service/ApiEndpoint";

function CheckIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-4 w-4">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5" />
        </svg>
    );
}

function CheckCross() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-4 w-4">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12" />
        </svg>
    )
}

function MemberDefaultDaysForm() {
    const [fetchDays, setFetchDays] = useState([]);
    const user = useSelector((state) => state.Auth.user)

    // GET All Areas
    const getAllAreas = useCallback(async () => {
        try {
            // Request
            const request = await getRequest(`/user/days/${user.id}`, {})
            const response = request?.data;
            setFetchDays(response);

            // Catch Error
        } catch (error) {
            // toast.error(error?.message)
            console.log(error);
        }
    }, [])

    useEffect(() => {
        getAllAreas();
    }, [getAllAreas])

    return (
        <div className="max-w-screen-xl flex flex-col md:flex-row gap-6 p-6 bg-gray-100 mx-auto">
            <Card color="gray" variant="gradient" className="w-full max-w-[20rem] p-8 mx-auto">
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 mb-8 rounded-none border-b border-white/10 pb-4 text-center">
                    <Typography
                        color="white"
                        className="font-bold text-2xl" >
                        Default Thaali Days
                    </Typography>
                </CardHeader>
                <CardBody className="p-0">
                    <ul className="flex flex-col gap-4">
                        {fetchDays?.map((el, idx) =>
                            <li className="flex items-center gap-4">
                                {el.active === 1 ?
                                    <span className="rounded-full border border-white/20 bg-green-500 p-1"><CheckIcon /></span> :
                                    <span className="rounded-full border border-white/20 bg-red-500 p-1"><CheckCross /></span>
                                }
                                <Typography className="font-normal text-xl">{dayName[el.day]}</Typography>
                            </li>
                        )}
                    </ul>
                </CardBody>
                <CardFooter className="mt-6 p-0">
                    <Button
                        size="lg"
                        color="white"
                        className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                        ripple={false}
                        fullWidth={true}
                    >
                        Want to Update?
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}
export default memo(MemberDefaultDaysForm);