import React, { memo } from "react";
import { useSelector } from "react-redux";
import TodayMenu from "../../components/comman/TodayMenu/TodayMenu";


function MemberHomePage() {
  const user = useSelector((state) => state.Auth.user)

  return (
    <section className="bg-transparent h-[auto] bgImage px-4 overflow-hidden w-screen">
      <TodayMenu user={user} />
    </section >
  );
}

export default memo(MemberHomePage);