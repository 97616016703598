import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./app/store/store.js";

// Import Components
import Menu from "../src/Menu/Menu.jsx";
import Layout from "../src/Routes/Layouts/Layout.jsx";

// Route Pages Component
import { Toaster } from "sonner";
import PushMenuPage from "./components/Calendar/PushMenuPage.jsx";
import PageNotFound from "./components/comman/PageNotFound/PageNotFound.jsx";
import Admin from "./Routes/Admin.jsx";
import AdminAddArea from "./Routes/Admin/Area/AdminAddArea.jsx";
import AdminArea from "./Routes/Admin/Area/AdminArea.jsx";
import AdminAddCoordinator from "./Routes/Admin/Coordinator/AdminAddCoordinator.jsx";
import AdminCoordinators from "./Routes/Admin/Coordinator/AdminCoordinators.jsx";
import AdminMembers from "./Routes/Admin/Member/AdminMembers.jsx";
import CoordinatorHomePage from "./Routes/Coordinator/CoordinatorHomePage.jsx";
import CoordinatorProfilePage from "./Routes/Coordinator/CoordinatorProfilePage.jsx";
import HomePage from "./Routes/HomePage.jsx";
import AdminProtectedLayout from "./Routes/Layouts/AdminProtectedLayout.jsx"; // Import the custom route component
import CoordinatorProtectedLayout from "./Routes/Layouts/CoordinatorProtectedLayout.jsx";
import MemberProtectedLayout from "./Routes/Layouts/MemberProtectedLayout.jsx";
import PublicProtectedLayout from "./Routes/Layouts/PublicProtectedLayout.jsx";
import Login from "./Routes/Login.jsx";
import MemberDefaultDaysForm from "./Routes/Member/MemberDefaultDaysForm.jsx";
import MemberHomePage from "./Routes/Member/MemberHomePage.jsx";
import MemberProfilePage from "./Routes/Member/MemberProfilePage.jsx";
import Register from "./Routes/Register.jsx";
import RegisterNewForm from "./Routes/RegisterNewForm.jsx";

// Main App Rendering
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        {/* TOASTER */}
        <Toaster
          richColors
          closeButton={"top-right"}
          expand={false}
          position="top-right"
        />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Public Routes */}
            <Route index element={<HomePage />} />
          </Route>

          {/* Users and Public Protected Route */}
          <Route path="/login" element={<PublicProtectedLayout />}>
            <Route index element={<Login />}></Route>
          </Route>

          {/* Admin Protected Routes */}
          <Route path="/admin" element={<AdminProtectedLayout />}>
            <Route index element={<Admin />} />
            <Route path="register" element={<Register />} />
            <Route path="rregister" element={<RegisterNewForm />} />
            <Route path="coordinator_master" element={<AdminCoordinators />} />
            <Route
              path="register/coordinator"
              element={<AdminAddCoordinator />}
            />
            <Route path="register/area" element={<AdminAddArea />} />
            <Route path="area_master" element={<AdminArea />} />
            <Route path="menu_master" element={<Menu />} />
            <Route path="push-menu" element={<PushMenuPage />} />
            <Route path="member_master" element={<AdminMembers />} />
          </Route>

          {/* Coordinator Protected Routes */}
          <Route path="/member" element={<MemberProtectedLayout />}>
            <Route index element={<MemberHomePage />} />
            <Route path="profile" element={<MemberProfilePage />} />
            <Route path="days" element={<MemberDefaultDaysForm />} />
          </Route>

          <Route path="/coordinator" element={<CoordinatorProtectedLayout />}>
            <Route index element={<CoordinatorHomePage />} />
            <Route path="profile" element={<CoordinatorProfilePage />} />
          </Route>

          {/* Catch-All Route */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
