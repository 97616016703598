import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import NavbarCustom from '../../components/Navbar/NavbarCustom';
import NavbarCustom2 from '../../components/Navbar/NavbarCustom2';

const PublicProtectedLayout = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.user)



  useEffect(() => {

    // setTimeout(() => {
    if (user && user.role === 'admin') {
      navigate("/admin");
    } else if (user && user.role === 'coordinator') {
      navigate("/coordinator");
    } else if (user && user.role === 'user') {
      navigate("/member");
    }
    // }, 1000);

  }, [user, navigate])

  return (
    <>
      {user && user.role === 'user' ? <NavbarCustom2 /> : <NavbarCustom />}
      <Outlet />
    </>
  )
};

export default memo(PublicProtectedLayout);
