import { Typography } from "@material-tailwind/react";
import React, { memo, useEffect, useMemo, useState } from 'react';
import { BsCalendar2Week } from "react-icons/bs";
import { FaMediumM, FaToolbox } from "react-icons/fa";
import { ImEnlarge } from "react-icons/im";
import { MdOutlinePhotoSizeSelectSmall } from "react-icons/md";

function Admin() {
  const [date, setDate] = useState([]);
  const [publicEvents, setPublicEvents] = useState(null);

  useEffect(() => {
    const storedEvents = JSON.parse(localStorage.getItem("publicEvents"));
    setPublicEvents(storedEvents);
  }, []);

  const todayMenu = useMemo(() => {
    if (!publicEvents) return [];
    const today = new Date().toISOString().split('T')[0];
    return publicEvents.filter(el => el.start.slice(0, 10) === today);
  }, [publicEvents]);

  const accordian = [
    {
      label: 'Total Thaali',
      value: 132,
      foot: 'Next Week',
      icon: <BsCalendar2Week />,
      fromClr: '#42424a',
      toClr: '#191919',
    },
    {
      label: 'Large',
      value: 50,
      foot: 'Next Week',
      icon: <ImEnlarge />,
      fromClr: '#49a3f1',
      toClr: '#1a73e8',
    },
    {
      label: 'Medium',
      value: 60,
      foot: 'Next Week',
      icon: <FaMediumM />,
      fromClr: '#eb4b50',
      toClr: '#f5c002',
    },
    {
      label: 'Small',
      value: 20,
      foot: 'Next Week',
      icon: <MdOutlinePhotoSizeSelectSmall />,
      fromClr: '#66bb6a',
      toClr: '#43a047',
    },
    {
      label: 'Barakati',
      value: 2,
      foot: 'Next Week',
      icon: <FaToolbox />,
      fromClr: '#42424a',
      toClr: '#191919',
    },
  ]

  useEffect(() => {
    const today = new Date();
    const todayDate = today.toString().split(" ");
    setDate(todayDate);
  }, []);

  return (
    <section className="max-w-screen-xl mx-auto">
      <figure className="relative h-auto w-full my-10">
        <figcaption className="flex w-[calc(100%-4rem)] mx-auto justify-between rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/15 saturate-200 backdrop-blur-sm">
          <div>
            <Typography variant='h3'
              className='text-gray-900 text-transparent bg-clip-text bg-gradient-to-r from-gray-800 to-gray-600'>
              {todayMenu[0]?.description || "No Menu Available"}
            </Typography>
            <Typography color="gray" className="mt-2 font-normal">
              {`${date[2]} ${date[1]} ${date[3]}, ${date[0]}`}
            </Typography>
          </div>
          <Typography variant='h6' color='gray' className="flex justify-center items-end">
            Today
          </Typography>
        </figcaption>
      </figure>

      <div className="gridItems mx-8 mb-4 gap-4">
        {accordian?.map(({ label, value, foot, icon, fromClr, toClr }, idx) => (
          <div key={idx} className="relative items rounded-xl border grid-cols-4 border-white bg-white/75 shadow-lg shadow-black/15 saturate-200 backdrop-blur-sm">
            <div className={`absolute -top-4 left-4 shadow-lg shadow-black/30 rounded-md h-10 w-10 flex justify-center items-center`}
              style={{ background: `linear-gradient(to bottom left, ${fromClr}, ${toClr})` }}  // Apply gradient with inline style
            >
              <div className="text-gray-50 text-xl">{icon}</div>
            </div>
            <div className="flex flex-col mx-4 my-2">
              <Typography variant='h6' className='text-right text-gray-700 tracking-wider xl:tracking-normal'>{label}</Typography>
              <Typography variant='h5' className='text-right text-gray-800'>{value}</Typography>
            </div>
            <hr className="border border-gray-400" />
            <Typography variant='small' className='mx-4 my-2 text-gray-700 font-normal'>{foot}</Typography>
          </div>
        ))
        }
      </div>
    </section >
  );
}
export default memo(Admin);