import { memo } from 'react';
import CurrentEvents from '../Events/CurrentEvents';


function HomePage() {
  return (
    <main className='max-w-screen-xl mx-auto'>
      <div className='flex justify-center mt-8'>
        <h1 className='text-bold text-3xl'>FMB APP HOME</h1>
      </div>

      {/* Calendar Default Events */}
      <div className='mx-auto my-4'>
        <CurrentEvents />
      </div>
    </main>
  )
}

export default memo(HomePage)
