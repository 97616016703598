import React, { memo, useEffect } from 'react';
import gsap from 'gsap'; // Import GSAP
import space404 from "../../../assets/svg/space_404.svg";
import ButtonAnimated from '../Button/AnimatedButton/ButtonAnimated';
import { Link } from 'react-router-dom';

function PageNotFound() {

    useEffect(() => {

        gsap.set("svg", { visibility: "visible" });
        gsap.to("#headStripe", {
            y: 0.5,
            rotation: 1,
            yoyo: true,
            repeat: -1,
            ease: "sine.inOut",
            duration: 1
        });
        gsap.to("#spaceman", {
            y: 0.5,
            rotation: 1,
            yoyo: true,
            repeat: -1,
            ease: "sine.inOut",
            duration: 1
        });
        gsap.to("#craterSmall", {
            x: -3,
            yoyo: true,
            repeat: -1,
            duration: 1,
            ease: "sine.inOut"
        });
        gsap.to("#craterBig", {
            x: 3,
            yoyo: true,
            repeat: -1,
            duration: 1,
            ease: "sine.inOut"
        });
        gsap.to("#planet", {
            rotation: -2,
            yoyo: true,
            repeat: -1,
            duration: 1,
            ease: "sine.inOut",
            transformOrigin: "50% 50%"
        });

        gsap.to("#starsBig g", {
            rotation: "random(-30,30)",
            transformOrigin: "50% 50%",
            yoyo: true,
            repeat: -1,
            ease: "sine.inOut"
        });
        gsap.fromTo(
            "#starsSmall g",
            { scale: 0, transformOrigin: "50% 50%" },
            { scale: 1, transformOrigin: "50% 50%", yoyo: true, repeat: -1, stagger: 0.1 }
        );
        gsap.to("#circlesSmall circle", {
            y: -4,
            yoyo: true,
            duration: 1,
            ease: "sine.inOut",
            repeat: -1
        });
        gsap.to("#circlesBig circle", {
            y: -2,
            yoyo: true,
            duration: 1,
            ease: "sine.inOut",
            repeat: -1
        });

        gsap.set("#glassShine", { x: -68 });

        gsap.to("#glassShine", {
            x: 80,
            duration: 2,
            rotation: -30,
            ease: "expo.inOut",
            transformOrigin: "50% 50%",
            repeat: -1,
            repeatDelay: 8,
            delay: 2
        });

        // Cleanup GSAP animations
        return () => {
            gsap.killTweensOf("*");
        };
    }, []);

    return (
        <main className='max-w-[600px] mx-auto px-8 py-4 bg-white rounded-xl m-8'>
            <div className="col-md-6 align-self-center">
                <img src={space404} alt="404_image" />
            </div>
            <div className="col-md-6 align-self-center">
                <h1 className='font-black text-3xl'>404</h1>
                <h2 className='font-large text-xl'>UH OH! You're lost.</h2>
                <p className='my-4 font-thin text-sm'>The page you are looking for does not exist.
                    How you got here is a mystery. But you can click the button below
                    to go back to the homepage.
                </p>
                <Link to="/"><ButtonAnimated btnClassName={"mx-0"} btnName={"H O M E"} /></Link>
            </div>
        </main>
    );
}

export default memo(PageNotFound);