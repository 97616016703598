import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    TabPanel,
    Tabs,
    Typography
} from "@material-tailwind/react";
import React, { memo, useState } from "react";
import { FaEye, FaEyeSlash, FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import "../../../Routes/Register.css";
import { postRequest } from "../../../service/ApiEndpoint";



function AdminAddCoordinator() {
    const [type, setType] = useState("paypal");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();

    // Member Login State
    const [form, setForm] = useState({
        title: "",
        name: "",
        email: "",
        phone: "",
        itsId: "",
        password: "",
        areaId: "",
        size: "",
        note: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    const isValidITSNumber = () => {
        return /^\d{8}$/.test(form.itsId);
    };

    const isValidPhoneNumber = () => {
        return /^\d{11}$/.test(form.phone);
    };

    const handleSubmit = async (e) => {
        console.log(form);
        e.preventDefault();

        if (!form.name) return toast.error("First enter your name");
        if (!form.email) return toast.error("Enter Email");
        if (!form.itsId) return toast.error("Enter ITS Number");
        if (!form.phone) return toast.error("Enter Contact Number");
        if (!form.password) return toast.error("Enter Password");

        if (!isValidITSNumber()) {
            toast.error("ITS Number must be exactly 8 digits.");
            return;
        }

        if (!isValidPhoneNumber()) {
            toast.error("Contact Number must be exactly 11 digits.");
            return;
        }

        try {

            const newName = (form.title + form.name).toLowerCase()
            // Sending Data
            const response = await postRequest('user', {
                name: newName,
                email: form.email.toLowerCase(),
                phone: form.phone,
                itsId: form.itsId,
                password: form.password,
                role: "coordinator",
                areaId: '0',
                size: form.size.toLowerCase(),
                note: form.note,
            });

            // SUCCESS
            if (response) {
                toast.success("Coordinator created successfully");
                // Clear Form
                setForm({
                    name: "",
                    email: "",
                    phone: "",
                    itsId: "",
                    password: "",
                    areaId: "",
                    size: "",
                    note: "",
                });
            }
            navigate("/admin/coordinator_master")

            // Catch Error
        } catch (error) {
            // toast.error(error?.message);
            console.log(error);
        }
    };

    const handlePasswordGenerate = () => {
        if (!form.name) return toast.error("First enter your name");
        if (!form.email) return toast.error("Enter Email");
        if (!form.itsId) return toast.error("Enter ITS Number");
        if (!form.phone) return toast.error("Enter Contact Number");

        const newPassword = form.name.split(" ");
        setForm({ ...form, password: newPassword[0] + '@' + form.phone.slice(1, 6) });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="cus-container mx-auto px-2 py-8 flex justify-center">
            <Card className="w-full sm:max-w-[40rem]">
                <CardHeader
                    color="gray"
                    floated={false}
                    shadow={false}
                    className="m-0 grid place-items-center px-4 py-8 text-center"
                >
                    <div className="mb-4 h-20 p-6 text-white">
                        <>
                            <FaUserPlus className="h-10 w-10 text-white mx-auto" />
                            <Typography variant="h5" color="white" className="mx-auto">
                                Register New Coordinator
                            </Typography>
                        </>
                    </div>
                </CardHeader>
                <CardBody className="mb-4">
                    <Tabs value={type} className="overflow-visible">
                        <div className="registerForm">
                            <TabPanel value="paypal" className="p-0">
                                <Card className="mx-auto w-full h-[330px]" shadow={false}>
                                    <form onSubmit={handleSubmit}>
                                        <CardBody className="flex flex-col gap-4 pt-2">
                                            <Typography className="text-xs mb-1 font-normal" variant="paragraph" color="gray" >
                                                Entered details must be unique
                                            </Typography>
                                            <div className="grid gap-4 mb-0 grid-cols-2">
                                                <div className="col-span-2 sm:col-span-1">
                                                    <Typography variant="small" className="mb-1 text-gray-700 font-medium">
                                                        Title &nbsp;<span className="text-gray-500 text-xs">(Optional)</span>
                                                    </Typography>
                                                    <div className="w-auto">
                                                        <select label="Select Title" name="title" onChange={handleChange} className="text-blue-gray-500 text-sm font-normal py-3 px-3 bg-transparent border border-blue-gray-200 rounded-md w-full h-full" defaultValue={""}>
                                                            <option disabled value="">None</option>
                                                            <option value="M. ">M. (Mulla)</option>
                                                            <option value="Shk. ">Shk. (Sheikh)</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-span-2 sm:col-span-1">
                                                    <Typography className="mb-1 text-gray-700 font-medium" variant="small">
                                                        Full Name
                                                    </Typography>
                                                    <Input label="Enter your coordinator name" size="lg" value={form.name} name="name" type="text" onChange={handleChange} />
                                                </div>
                                                <div className="col-span-2 sm:col-span-1">
                                                    <Typography className="mb-1 text-gray-700 font-medium" variant="small">
                                                        Email
                                                    </Typography>
                                                    <Input label="Enter your coordinator email" size="lg" value={form.email} name="email" type="email" onChange={handleChange} />
                                                </div>
                                                <div className="col-span-2 sm:col-span-1">
                                                    <Typography className="mb-1 text-gray-700 font-medium" variant="small">
                                                        ITS Number
                                                    </Typography>
                                                    <Input label="Enter your ITS number" size="lg" value={form.itsId}
                                                        name="itsId" type="number"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Allow only digits and prevent input longer than 8 characters
                                                            if (/^\d*$/.test(value) && value.length <= 8) {
                                                                setForm({ ...form, [e.target.name]: value });
                                                            }
                                                        }} />
                                                </div>
                                                <div className="col-span-2 sm:col-span-1">
                                                    <Typography className="mb-1 text-gray-700 font-medium" variant="small">
                                                        Contact Number
                                                    </Typography>
                                                    <Input label="Enter your contact number" size="lg" value={form.phone} name="phone"
                                                        type="phone"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            // Allow only digits and prevent input longer than 8 characters
                                                            if (/^\d*$/.test(value) && value.length <= 11) {
                                                                setForm({ ...form, [e.target.name]: value });
                                                            }
                                                        }} />
                                                </div>
                                                <div className="col-span-2 sm:col-span-1 relative">
                                                    <Typography variant="small" className="mb-1 text-gray-700 font-medium flex justify-between">
                                                        <span>Password</span>
                                                        <button type="button" className="absolute right-0 -top-1.5 text-sm bg-green-400 text-white rounded-lg px-2 py-1 my-auto" onClick={handlePasswordGenerate}>Auto Generate</button>
                                                    </Typography>
                                                    <div className="relative">
                                                        <Input
                                                            label="Enter your coordinator password"
                                                            size="lg"
                                                            value={form.password}
                                                            name="password"
                                                            type={passwordVisible ? "text" : "password"}
                                                            onChange={handleChange} />
                                                        <button type="button" className="absolute inset-y-0 right-0 flex items-center pr-3" onClick={togglePasswordVisibility} >
                                                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="py-2">
                                            <Button className="bg-gray-500" type="submit" fullWidth>
                                                Register Now
                                            </Button>
                                        </CardFooter>
                                    </form>
                                </Card>
                            </TabPanel>
                        </div>
                    </Tabs>
                </CardBody>
            </Card>
        </div >
    );
}

export default memo(AdminAddCoordinator);