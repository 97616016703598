import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  TabPanel,
  Tabs,
  Textarea,
  Typography
} from "@material-tailwind/react";
import React, { memo, useCallback, useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaUserPlus } from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { setAreas } from "../app/slices/authSlice2";
import { capitalizeWords } from "../extraStuff";
import "../Routes/Register.css";
import { getRequest, postRequest } from "../service/ApiEndpoint";




function Register() {
  const [type, setType] = useState("paypal");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const [fetchArea, setFetchArea] = useState([]);
  const dispatch = useDispatch();

  // Member Login State
  const [form, setForm] = useState({
    title: "",
    name: "",
    email: "",
    phone: "",
    itsId: "",
    password: "",
    role: "user",
    areaId: "",
    size: "",
    note: "",

  })

  // GET All Areas
  const getAllAreas = useCallback(async () => {
    try {

      // Request
      const request = await getRequest('/area', {})

      const response = request?.data;

      setFetchArea(response);
      dispatch(setAreas(response));

      // Catch Error
    } catch (error) {
      // toast.error(error?.message)
      console.log(error);
    }
  }, [dispatch])

  useEffect(() => {
    getAllAreas();
  }, [dispatch, getAllAreas])

  const handleSubmit = async (e) => {
    console.log(form);
    e.preventDefault();

    if (!form.name) return toast.error("First Enter Name");
    if (!form.email) return toast.error("Enter Email");
    if (!form.itsId) return toast.error("Enter ITS Number");
    if (!form.phone) return toast.error("Enter Contact Number");
    if (!form.password) return toast.error("Enter Password");
    if (!form.areaId) return toast.error("Select Area Name");
    if (!form.size) return toast.error("Select thaali size");

    if (!isValidITSNumber()) {
      toast.error("ITS Number must be exactly 8 digits.");
      return;
    }

    if (!isValidPhoneNumber()) {
      toast.error("Contact Number must be exactly 11 digits.");
      return;
    }

    const newName = form.title + form.name;

    try {
      // Sending Data
      const request = await postRequest('/user', {
        name: newName.toLowerCase(),
        email: form.email,
        phone: form.phone,
        itsId: form.itsId,
        password: form.password,
        role: form.role,
        areaId: form.areaId,
        size: form.size,
        note: form.note,
      })

      // SUCCESS
      const response = request.data;
      if (response) {
        toast.success("Member Registered Successfully")
        // Clear Form
        setForm({
          title: "",
          name: "",
          email: "",
          itsId: "",
          phone: "",
          password: "",
          areaId: "",
          size: "",
          note: "",
        })
        navigate("/admin/member_master")
      }

      // Catch Error
    } catch (error) {
      // toast.error(error?.message)
      console.log(error);
    }
  }

  const handlePasswordGenerate = () => {
    if (!form.name) return toast.error("First enter your name");
    if (!form.email) return toast.error("Enter Email");
    if (!form.itsId) return toast.error("Enter ITS Number");
    if (!form.phone) return toast.error("Enter Contact Number");

    const newPassword = form.name.split(" ");
    setForm({ ...form, password: newPassword[0] + '@' + form.phone.slice(1, 6) });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const isValidITSNumber = () => {
    return /^\d{8}$/.test(form.itsId);
  };

  const isValidPhoneNumber = () => {
    return /^\d{11}$/.test(form.phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm(prevForm => ({ ...prevForm, [name]: value }));
  };

  return (
    <div className="cus-container mx-auto px-2 py-8 flex justify-center">
      <Card className="w-full sm:max-w-[40rem]">
        <CardHeader
          color="gray"
          floated={false}
          shadow={false}
          className="m-0 grid place-items-center px-4 py-6 text-center">
          <div className="mb-4 h-20 p-2 text-white">
            <FaUserPlus className="h-10 w-10 text-white mx-auto" />
            <Typography variant="h5" color="white" className="mx-auto">Register New Member</Typography>
          </div>
        </CardHeader>
        <CardBody className="mb-2 faltuH">
          <Tabs value={type} className="registerForm h-full">
            <div className="">
              {/* Coordinator Login */}
              <TabPanel value="paypal" className="p-0 h-full">
                <Card className="mx-auto w-full h-full" shadow={false}>
                  <form onSubmit={handleSubmit}>
                    <CardBody className="flex flex-col gap-4 pt-0">
                      <div className="grid gap-4 mb-0 grid-cols-2">
                        <div className="col-span-2 sm:col-span-1">
                          <Typography variant="small" className="mb-1 text-gray-700 font-medium">
                            Title &nbsp;<span className="text-gray-500 text-xs">(Optional)</span>
                          </Typography>
                          <div className="w-auto">
                            <select label="Select Title" name="title" onChange={(e) => setForm({ ...form, title: e.target.value })} className="text-blue-gray-500 text-sm font-normal py-3 px-3 bg-transparent border border-blue-gray-200 rounded-md w-full h-full" defaultValue={""}>
                              <option disabled value="">None</option>
                              <option value="M. ">M. (Mulla)</option>
                              <option value="Shk. ">Shk. (Sheikh)</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <Typography variant="small" className="mb-1 text-gray-700 font-medium">
                            Full Name <span className="text-red-500">*</span></Typography>
                          <Input label="Enter member full name" size="lg" value={form.name} name="name" type="text" onChange={handleChange} />
                        </div>
                        <div className="col-span-2">
                          <Typography variant="small" className="mb-1 text-gray-700 font-medium">
                            Email <span className="text-red-500">*</span></Typography>
                          <Input label="Enter your member email" size="lg" value={form.email} name="email" type="email" onChange={handleChange} />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <Typography variant="small" className="mb-1 text-gray-700 font-medium">
                            Contact Number <span className="text-red-500">*</span></Typography>
                          <Input label="Enter member whatsApp number" size="lg" value={form.phone} name="phone" type="phone" onChange={(e) => { const value = e.target.value; if (/^\d*$/.test(value) && value.length <= 11) { setForm({ ...form, [e.target.name]: value }) } }} />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <Typography variant="small" className="mb-1 text-gray-700 font-medium">
                            ITS Number <span className="text-red-500">*</span></Typography>
                          <Input label="Enter member ITS number" size="lg" value={form.itsId} name="itsId" type="number" onChange={(e) => { const value = e.target.value; if (/^\d*$/.test(value) && value.length <= 8) { setForm({ ...form, [e.target.name]: value }); } }} />
                        </div>

                        {/* AREA AND COORDINATOR DETAILS */}
                        <div className="col-span-2 sm:col-span-1">
                          <Typography variant="small" className="mb-1 text-gray-700 mb-2 font-medium">
                            Area <span className="text-red-500">*</span></Typography>
                          <div className="w-auto">
                            <select name="areaId" onChange={handleChange} className="text-blue-gray-500 text-sm font-normal py-3 px-3 bg-transparent border border-blue-gray-200 rounded-md w-full h-full" defaultValue={""}>
                              <option disabled value=''>Select area name</option>
                              {fetchArea?.map((el) => <option value={el.id} key={el.id}>{capitalizeWords(el.name)}</option>)}
                            </select>
                          </div>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <Typography variant="small" className="mb-1 text-gray-700 mb-2 font-medium flex items-center">
                            Select thaali size <span className="text-red-500 mr-1.25">*</span>
                            <i title="Select on permanent basis"><IoIosInformationCircle /></i>
                          </Typography>
                          <div className="w-auto">
                            <select onChange={handleChange} name='size' className="text-blue-gray-500 text-sm font-normal py-3 px-3 bg-transparent border border-blue-gray-200 rounded-md w-full h-full" defaultValue={""}>
                              <option disabled value=''>Select thaali size</option>
                              <option value='large'>Large</option>
                              <option value='medium'>Medium</option>
                              <option value='small'>Small</option>
                              <option value='barakati'>Barakati</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <Typography variant="small" className="mb-1 text-gray-700 mb-2 font-medium">Comments &nbsp;<span className="text-gray-500 text-xs">(Optional)</span></Typography>
                          <Textarea resize={true} label="Your comments" name='note' value={form.note} className="min-h-[43px] h-auto" onChange={handleChange} />
                        </div>
                        <div className="col-span-2 sm:col-span-1 relative">
                          <Typography variant="small" className="mb-1 text-gray-700 mb-2 font-medium flex justify-between">
                            <span>Password <span className="text-red-500">*</span></span>
                            <button type="button" className="absolute right-0 -top-1.5 text-sm bg-green-400 text-white rounded-lg px-2 py-1 my-auto" onClick={handlePasswordGenerate}>Auto Generate</button>
                          </Typography>
                          <div className="relative">
                            <Input
                              label="Enter member password"
                              size="lg"
                              value={form.password}
                              name="password"
                              type={passwordVisible ? "text" : "password"}
                              onChange={handleChange}
                            />
                            <button type="button" className="absolute inset-y-0 right-0 flex items-center pr-3" onClick={togglePasswordVisibility} >
                              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                    <CardFooter className="py-2">
                      <Button className="bg-gray-500 pb-2" type="sumit" fullWidth>Register Now</Button>
                    </CardFooter>
                  </form>
                </Card>
              </TabPanel>
            </div>
          </Tabs>
        </CardBody>
      </Card>
    </div >
  )
}

export default memo(Register)