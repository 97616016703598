import React, { memo } from 'react';
import CoordinatorDashboard from './CoordinatorDashboard';

function CoordinatorProfilePage() {

    return (
        <div className='max-w-screen-xl mx-auto'>
            <CoordinatorDashboard />
        </div>
    )
}

export default memo(CoordinatorProfilePage)