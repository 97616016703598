import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvents } from '../app/slices/eventsSlice';
import { getRequest } from "../service/ApiEndpoint";
import "./CurrentEvents.css";

function CurrentEvents() {
    // Get events from Redux store
    const events = useSelector(state => state.calendarEvents);
    const dispatch = useDispatch();

    // Ref to store cached events from localStorage
    const publicEvents = useRef(JSON.parse(localStorage.getItem("publicEvents")) || null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch latest events from the API
                const request = await getRequest(`/event`, {});
                const response = request?.data;

                // If the fetched response is different from the cached one
                if (JSON.stringify(response) !== JSON.stringify(publicEvents.current)) {
                    // Update cache and Redux store with fresh data
                    publicEvents.current = response;  // Cache in useRef
                    localStorage.setItem("publicEvents", JSON.stringify(response));  // Cache in localStorage
                    dispatch(updateEvents(response));  // Update Redux store
                } else {
                    // console.log('Using cached data, no changes detected');
                }
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };

        // Immediately dispatch cached data if available
        if (publicEvents.current) {
            dispatch(updateEvents(publicEvents.current));  // Use cached events from useRef
        }

        // Fetch new data from the API regardless of cache
        fetchEvents();

    }, [dispatch]);

    const renderEventContent = useCallback((eventInfo) => {
        return (<div dangerouslySetInnerHTML={{ __html: eventInfo.event.extendedProps.description }} />);
    }, []);

    return (
        <div className='cus-container mx-auto bg-white p-4 rounded-3xl border-2 border-gray-400 shadow-2xl mt-14'>
            <div className='font-black my-2 base-text'>
                <h3 style={{ fontVariant: 'small-caps' }}>Thaali Calendar</h3>
            </div>
            {/* Full Calendar */}

            <div className='bg-gray-600 rounded-xl px-4 py-6 text-media shadow-2xl text-white text-xl' style={{ fontVariant: 'small-caps' }}>
                <FullCalendar
                    // plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    // dayGridMonth,timeGridWeek,timeGridDay
                    plugins={[dayGridPlugin, listPlugin]}
                    initialView={'listWeek'}
                    headerToolbar={{
                        start: 'today',
                        center: "title",
                        end: "prev,next",
                        // right: 'dayGridMonth,listWeek',
                    }}
                    events={events}
                    eventContent={renderEventContent}
                // dateClick={handleDateClick}
                />
            </div>
        </div>
    )
}

export default memo(CurrentEvents)