import {
    Card,
    Typography
} from "@material-tailwind/react";
import { memo, useEffect, useState } from "react";


// Table Head 
const TABLE_HEAD = [
    {
        head: "Select",
    },
    {
        head: "Date",
    },
    {
        head: "Menu",
    },
];

function MaterialTable({ data, selectedMenus, setSelectedMenus, pushBtn, setPushBtn }) {
    // Local state to track if "select all" checkbox is checked
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        setIsAllSelected(data.every(({ start }) => selectedMenus.newDates.includes(start)));
    }, [selectedMenus, data]);

    const handleCheckboxChange = (isChecked, start) => {
        setSelectedMenus((prevSelected) => {
            const newDates = isChecked
                ? [...prevSelected.newDates, start] // Add the selected date to the array
                : prevSelected.newDates.filter((item) => item !== start); // Remove the unchecked date

            setPushBtn({ ...pushBtn, disa: newDates.length === 0 });

            return {
                ...prevSelected,
                newDates,
            };
        });
    };

    const handleSelectAllChange = (isChecked) => {
        setIsAllSelected(isChecked);
        setSelectedMenus((prevSelected) => {
            const newDates = isChecked
                ? data.map(({ start }) => start) // Select all dates
                : []; // Deselect all dates

            setPushBtn({ ...pushBtn, disa: newDates.length === 0 });

            return {
                ...prevSelected,
                newDates,
            };
        });
    };

    return (
        <Card className="w-full max-h-[250px] overflow-auto whitespace-nowrap rounded-none shadow-none">
            <table className="w-full min-w-max table-auto text-left">
                <thead>
                    <tr>
                        {data && data?.length > 0 && (
                            <>
                                <th className="border-b border-gray-300 px-2 py-1 w-auto">
                                    <input
                                        type="checkbox"
                                        checked={isAllSelected}
                                        onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    />
                                </th>
                                {TABLE_HEAD.slice(1).map(({ head, icon }) => (
                                    <th key={head} className="border-b border-gray-300 px-2 py-1 w-auto">
                                        <div className="flex items-center gap-1">
                                            {icon}
                                            <Typography
                                                color="blue-gray"
                                                variant="small"
                                                className="!font-bold"
                                            >
                                                {head}
                                            </Typography>
                                        </div>
                                    </th>
                                ))}
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data?.map(({ id, description, start }) => {
                        const isSelected = selectedMenus.newDates.includes(start);
                        return (
                            <tr key={id}>

                                <td className="px-2 py-1 w-auto flex items-center">
                                    <input
                                        type="checkbox"
                                        id={id}
                                        checked={isSelected}
                                        onChange={(e) => handleCheckboxChange(e.target.checked, start)} />
                                </td>
                                <td className="px-2">
                                    <label htmlFor={id}>
                                        <Typography variant="small" className="font-normal text-gray-600">
                                            {start.slice(0, 10)}
                                        </Typography>
                                    </label>
                                </td>
                                <td className="px-2">
                                    <label htmlFor={id}>
                                        <Typography variant="small" className="font-semibold text-gray-700">
                                            <div dangerouslySetInnerHTML={{ __html: description }} />
                                        </Typography>
                                    </label>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Card >
    );
}

export default memo(MaterialTable);
