import React, { memo } from 'react'
import { Link } from 'react-router-dom'

function CoordinatorHomePage() {

  return (
    <div className='max-w-screen-xl flex flex-col justify-center items-center mx-auto'>
      <h1 className='text-2xl font-bold mt-14 mr-auto'>Hello coordinator </h1>

      <div className='mr-auto mt-8'>
        <Link to='/coordinator/profile'>Profile</Link>
      </div>
    </div>
  )
}

export default memo(CoordinatorHomePage)