import { createSlice } from "@reduxjs/toolkit";
// Initial state with fallback
const eventsSlice = createSlice({
  name: "events",
  initialState: {
    events: [],
    monthEvents: [],
  },
  reducers: {
    updateEvents: (state, action) => {
      state.events = action.payload;
    },
    updateMonthEvents: (state, action) => {
      state.monthEvents = action.payload;
    },
    addEvent: (state, action) => {
      state.events.push(action.payload);
    },
  },
});

export const { updateEvents, updateMonthEvents, addEvent } =
  eventsSlice.actions;
export default eventsSlice.reducer;
