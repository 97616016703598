
import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    Typography,
} from "@material-tailwind/react";
import React, { memo, useCallback, useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { patchRequest, postRequest, putRequest } from "../../service/ApiEndpoint";
import MyEditor from "./MyEditor";

function EditorWithDialog({ events, eventId, setEventId, currentDate, setCurrentDate, JSXValue, setJSXValue, dailogOpen, setDailogOpen }) {
    const val = useSelector(state => state.calendarEvents.events)
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    // Closing dialog box and clearing state
    const handleOpen = () => {
        setDailogOpen(!dailogOpen)
        if (currentDate) {
            setCurrentDate("");
            setJSXValue("");
        }
    }

    const handleDelete = useCallback(async (item) => {
        try {
            const response = await patchRequest(`/event/delete/${item}`);
            if (response) {
                toast.success(response?.data?.message);
                setEventId(null);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    // CONFIRM BEFORE DELETE EVENT
    const handleOpenConfirm = () => {
        setIsConfirmOpen(true);
    };

    const handleDeleteConfirmed = () => {
        handleDelete(eventId);
        setIsConfirmOpen(false);
    };

    const createNewEvent = async () => {
        if (!JSXValue) { return false }
        try {
            const request = await postRequest('/event', {
                title: "Menu",
                description: JSXValue,
                start: currentDate,
                end: currentDate,
            })
            const response = request?.data;
            if (response) {
                toast.success(response?.message);
                // setTimeout(() => {
                //     window.location.reload();
                // }, 800);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateEvent = async () => {
        const filteredEvents = events.filter(el => el.start === currentDate);
        try {
            const request = await putRequest(`/event/${filteredEvents[0].id}`, {
                title: "Menu",
                description: JSXValue,
                start: currentDate,
                end: currentDate,
            })
            const response = request?.data;
            if (response) {
                toast.success(response?.message)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddMenu = async () => {
        const existingEvent = val?.find(el => el.start === currentDate);
        if (existingEvent) {
            updateEvent();
        } else {
            createNewEvent();
        }
        handleOpen();
    }

    useEffect(() => {
        if (!currentDate || !JSXValue) return;  // Ensure currentDate and JSXValue are available

        // Get all events from localStorage
        const allEvents = JSON.parse(localStorage.getItem("monthEvents")) || [];

        // Check if the event already exists in the array, update it
        const newEvents = allEvents.map(el => {
            if (el.start === currentDate) {
                return { ...el, description: JSXValue };  // Update description
            }
            return el;  // Return other events unchanged
        });

        // Update only if there is a change in the event data
        if (JSON.stringify(allEvents) !== JSON.stringify(newEvents)) {
            localStorage.setItem("monthEvents", JSON.stringify(newEvents));
        }

        console.log("Updated events", newEvents);
    }, [currentDate, JSXValue]);  // Trigger effect when currentDate or JSXValue changes

    return (
        <>
            <Dialog
                open={dailogOpen}
                handler={handleOpen}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }}>
                <DialogHeader>Set Menu</DialogHeader>

                <div className="flex justify-between items-center mx-4">
                    <Typography variant="h6" color="blue-gray" className="bg-gray-400 p-2 rounded-t-lg">Menu Date : {currentDate}</Typography>
                    <MdDeleteForever className="text-2xl text-red-700 cursor-pointer" onClick={handleOpenConfirm} />
                </div>

                <DialogBody>
                    <MyEditor existingVal={JSXValue} setJSXValue={setJSXValue} />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpen}
                        className="mr-1"
                        title="Cancel">
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" title="Add Menu"
                        onClick={handleAddMenu}>
                        <span>Add Menu</span>
                    </Button>
                </DialogFooter>
            </Dialog >

            {/* Confirmation Dailog Modal */}
            <Dialog open={isConfirmOpen} handler={() => setIsConfirmOpen(!isConfirmOpen)}>
                <h1 className='font-black text-gray-800 text-xl m-4'>Confirm Deletion</h1>
                <p className='font-normal text-gray-600 text-md m-4'>
                    Are you sure you want to delete the Event?
                </p>
                <DialogFooter className='flex gap-2'>
                    <Button variant="text" color="red" onClick={() => setIsConfirmOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleDeleteConfirmed}>
                        Confirm
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}
export default memo(EditorWithDialog);