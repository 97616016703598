import React from 'react'
import Calendar from '../components/Calendar/Calendar'

function Menu() {
    return (
        <div className='cus-container mx-auto my-4'>
            <Calendar />
        </div>
    )
}

export default Menu