import { Typography } from '@material-tailwind/react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { getRequest, putRequest } from "../../../service/ApiEndpoint";

function TodayMenu({ user }) {
    const [date, setDate] = useState([]);
    const [todayMenu, setTodayMenu] = useState([]);
    const [menuJoinDate, setMenuJoinDate] = useState([]);

    const getTodayMenu = useCallback(async () => {
        try {
            // Request
            const request = await getRequest(`/event/today`, {})
            const response = request?.data;
            setTodayMenu(response);

            // Catch Error
        } catch (error) {
            toast?.error("Network Error")
        }
    }, [])

    const getMenuForDates = useCallback(async () => {
        try {
            // Request
            const request = await getRequest(`/event/find-date/${user?.id}`, {})
            const response = request?.data;
            setMenuJoinDate(response?.data);

            // Catch Error
        } catch (error) {
            toast?.error("Network Error")
        }
    }, [user?.id])

    const handleUpdateActive = useCallback(async (e) => {
        if (!e) return false;

        try {
            // Request
            const request = await putRequest(`/event/user-date/${e}`, {})
            const response = request?.data;
            if (response?.message === 'Success') {
                toast?.success("Updated Successfully");
                window.location.reload();
            }
            // Catch Error
        } catch (error) {
            toast?.error("Network Error")
        }
    }, [])

    useEffect(() => {
        const today = new Date();
        const todayDate = today?.toString().split(" ");
        setDate(todayDate)
        getTodayMenu();
        getMenuForDates();
    }, [getTodayMenu, getMenuForDates])

    function getDayName(dateString) {
        const date = new Date(dateString);
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayName = daysOfWeek[date?.getDay()];
        return dayName;
    }

    return (
        <>
            <figcaption className="max-w-screen-xl mx-auto flex w-[calc(100%-4rem)] justify-between rounded-xl border border-white bg-white/60 my-8 py-4 px-6 shadow-lg shadow-black/25 saturate-200 backdrop-blur-xl">
                <div>
                    <Typography color="blue-gray" className='text-md lg:text-2xl font-extrabold'>
                        <span dangerouslySetInnerHTML={{ __html: todayMenu[0]?.description || "No Menu available" }} />
                    </Typography>
                    <Typography color="gray" className="mt-2 font-normal">
                        {`${date[2]} ${date[1]} ${date[3]}, ${date[0]}`}
                    </Typography>
                </div>
                <Typography variant='h6' color='gray' className="flex justify-center items-end">
                    Today
                </Typography>
            </figcaption>
            <div className="w-full overflow-auto mx-auto justify-between py-4 lg:py-14">
                <table className='w-auto mx-auto rounded-lg border border-gray-400 bg-white/60 py-2 px-1 shadow-xl shadow-black/25 saturate-200 backdrop-blur-lg'>
                    <thead>
                        <tr>
                            <th className='border border-gray-300 bg-gray-500 text-white text-left px-2 py-1'>Date</th>
                            <th className='border border-gray-300 bg-gray-500 text-white text-left px-2 py-1'>Day</th>
                            <th className='border border-gray-300 bg-gray-500 text-white text-left px-2 py-1'>Menu</th>
                            <th className='border border-gray-300 bg-gray-500 text-white text-left px-2 py-1'>Receiving Thaali</th>
                        </tr>
                    </thead>
                    <tbody>
                        {menuJoinDate?.map(el =>
                            <tr key={el?.id} className='text-sm'>
                                <td className='border border-gray-500 px-2 py-1 text-gray-800 min-w-[90px]'>{el?.date?.slice(0, 10)}</td>
                                <td className={`border border-gray-500 px-2 py-1 text-gray-50 text-sm font-semibold ${el?.active ? 'bg-green-500' : 'bg-red-500'}`}>{getDayName(el?.date?.slice(0, 10))}</td>
                                <td className='border border-gray-500 px-2 py-1 text-gray-800 min-w-[200px]'>
                                    <span dangerouslySetInnerHTML={{ __html: el?.description }} />
                                </td>
                                <td className='border border-gray-500 px-2 py-1 text-gray-800'>
                                    {el?.active ?
                                        <label className="inline-flex items-center me-5 cursor-pointer">
                                            <input type="checkbox" value="" className="sr-only peer" checked onClick={() => handleUpdateActive(el?.id)} />
                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-500"></div>
                                        </label>
                                        :
                                        <label className="inline-flex items-center me-5 cursor-pointer">
                                            <input type="checkbox" value="" className="sr-only peer" checked onClick={() => handleUpdateActive(el?.id)} />
                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-500"></div>
                                        </label>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div >
        </>
    )
}
export default memo(TodayMenu)
