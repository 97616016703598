import { Button, Dialog, DialogFooter } from '@material-tailwind/react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';
import { MdDeleteOutline } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { setCoordinators } from '../../../app/slices/authSlice2';
import ButtonAnimated from '../../../components/comman/Button/AnimatedButton/ButtonAnimated';
import ToggleButtonAnimated from '../../../components/comman/Button/AnimatedButton/ToggleButtonAnimated';
import LabelComponent from '../../../components/comman/Label/LabelComponent';
import AdminCoordinatorEditModal from '../../../components/ModalForms/AdminCoordinatorEditModal';
import { capitalizeWords } from '../../../extraStuff';
import { deleteReq, getRequest } from '../../../service/ApiEndpoint';


function AdminCoordinators() {
    const tableHead = ["S.No.", "Full Name", "Email", "Contact Number", "ITS Number", "Action"]
    const [fetchData, setFetchData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [coordModal, setCoordModal] = useState(false)
    const [passData, setPassData] = useState({})

    useEffect(() => {
        // GET All Coordinators
        const getAllUsers = async () => {
            try {
                // Request
                const response = await getRequest('/user?role=coordinator', {})
                setFetchData(response.data);
                dispatch(setCoordinators(response.data));
                // Catch Error
            } catch (error) {
                // toast.error(error?.message)
                console.log(error);
            }
        }
        getAllUsers();
    }, [dispatch])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDelete = useCallback(async (item) => {
        try {
            const response = await deleteReq(`/user/${item}`);
            if (response) {
                const response = await getRequest('/user?role=coordinator', {})
                setFetchData(response?.data);
                dispatch(setCoordinators(response?.data));
                toast.success("Coordinator Deleted Successfully");
            }
            // Catch Error
        } catch (error) {
            // toast.error(error?.message);
            console.log(error);
        }
    }, [dispatch])

    const handleRegister = () => {
        navigate("/admin/register/coordinator");
    }

    // CONFIRM BEFORE DELETE AREA
    const handleOpenConfirm = (id) => {
        setDeleteId(id);
        setIsConfirmOpen(true);
    };

    const handleDeleteConfirmed = () => {
        handleDelete(deleteId);
        setIsConfirmOpen(false);
    };

    return (
        <div className='max-w-screen-xl mt-14 mx-auto bg-white py-2 rounded-xl shadow-xl'>
            {coordModal &&
                <AdminCoordinatorEditModal coordModal={coordModal} passData={passData} setCoordModal={setCoordModal} setFetchData={setFetchData} />
            }
            <LabelComponent labelNameClass={'text-3xl font-medium text-pri mx-4'} labelName={'Coordinators'} />

            <div className='motif-central'>&nbsp;</div>
            <ButtonAnimated btnIcon={<IoPersonAddSharp className='text-xl' />} btnName={'Add Coordinator'}
                btnOnClick={handleRegister}
            />

            <div className="relative overflow-x-auto shadow-md rounded-lg my-4 border-2 border-sec bg-white mx-2">
                {/* TABLE */}
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {/* TABLE HEAD */}
                    <thead className="text-xs uppercase bg-sec text-sec">
                        <tr>
                            {tableHead.map((headItem, idx) => <th scope="col" className="pl-2 px-1 py-3 font-semibold" key={idx}>
                                {headItem}
                            </th>)}
                        </tr>
                    </thead>
                    {/* TABLE BODY */}
                    <tbody>
                        {fetchData?.map((el, idx) =>
                            <tr className='hover:bg-[--table-bg-hoverBlue]' key={el.id}>
                                <th scope="row" className="px-1 py-1 font-medium base-text whitespace-nowrap dark:text-gray-900 mx-2 pl-2">
                                    {idx + 1}
                                </th>
                                <td className="pl-2 py-1 base-text">
                                    {capitalizeWords(el?.name)}
                                </td>
                                <td className="pl-2 py-1 base-text">
                                    {el.email}
                                </td>
                                <td className="pl-2 py-1 base-text">
                                    {el.phone}
                                </td>
                                <td className="pl-2 py-1 base-text">
                                    {el.itsId}
                                </td>
                                <td className="py-2 flex items-center">
                                    <ToggleButtonAnimated btnIcon={
                                        <TbEdit className='text-green-600 cursor-pointer text-xl' />
                                    } onBtnClick={() => { setPassData(el); setCoordModal(!coordModal) }} />
                                    <ToggleButtonAnimated btnIcon={
                                        < MdDeleteOutline className='text-red-600 cursor-pointer text-xl' />
                                    } onBtnClick={() => handleOpenConfirm(el.id)} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <LabelComponent labelName={`Displaying ${fetchData.length} records`} labelNameClass={'m-2 text-xs base-text'} />


            {/* Confirmation Dailog Modal */}

            <Dialog open={isConfirmOpen} handler={() => setIsConfirmOpen(!isConfirmOpen)}>
                <h1 className='font-black text-gray-800 text-xl m-4'>Confirm Deletion</h1>
                <p className='font-normal text-gray-600 text-md m-4'>
                    Are you sure you want to delete the Coordinator?
                </p>
                <DialogFooter className='flex gap-2'>
                    <Button variant="text" color="red" onClick={() => setIsConfirmOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleDeleteConfirmed}>
                        Confirm
                    </Button>
                </DialogFooter>
            </Dialog>
        </div >
    )
}

export default memo(AdminCoordinators)