import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import NavbarCustom from '../../components/Navbar/NavbarCustom';
import NavbarCustom2 from '../../components/Navbar/NavbarCustom2';


function Layout() {
    const user = useSelector(state => state.Auth.user);
    return (
        <>
            {user && user.role === 'user' ? <NavbarCustom2 /> : <NavbarCustom />}
            <Outlet />
        </>
    )
}

export default Layout