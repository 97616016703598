import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import NavbarCustom from '../../components/Navbar/NavbarCustom';

const CoordinatorProtectedLayout = () => {

  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.user)

  useEffect(() => {
    if (!user || user.role !== 'coordinator') {
      setTimeout(() => {
        navigate("/");
      }, 1);
    }
  }, [user, navigate])

  return (
    <>
      <NavbarCustom />
      <Outlet />
    </>
  )
};

export default memo(CoordinatorProtectedLayout);
