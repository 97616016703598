import React, { memo } from 'react';
import "./DatePicker.css";

function DatePicker({ selectedDate, setSelectedDate }) {

    return (
        <div className="relative max-w-sm flex flex-wrap">
            <label htmlFor="datepicker-autohide" className='text-sm mt-2'>Select from which date you want to see events</label>
            <input id="datepicker-autohide" type="date"
                className="mt-2 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-[150px] p-1.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select date" value={selectedDate} name='date_picker' onChange={(e) => setSelectedDate(e.target.value)} />
        </div>
    );
}

export default memo(DatePicker);