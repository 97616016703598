import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import NavbarCustom2 from "../../components/Navbar/NavbarCustom2";

const MemberProtectedLayout = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.user)

  useEffect(() => {
    if (!user || user.role !== 'user') {
      setTimeout(() => {
        navigate("/"); // Redirect to the login page
      }, 1);
    }
  }, [user, navigate])

  return (
    <>
      <NavbarCustom2 />
      <Outlet />
    </>
  )
};

export default memo(MemberProtectedLayout);
