import React, { memo, useState } from "react";
import { BsPersonBadgeFill } from "react-icons/bs";
import { FaToolbox } from "react-icons/fa";
import { HiHome } from "react-icons/hi2";
import { IoMail, IoSunnyOutline } from "react-icons/io5";
import { MdFeedback, MdOutlineMenuBook, MdRememberMe } from "react-icons/md";
import { PiCellSignalFullBold, PiMoonDuotone } from "react-icons/pi";
import { RxBorderSolid } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { setLogout } from "../../app/slices/authSlice2";
import fmbLogo from "../../assets/images/fmb_logo.png";
import "./Navbar.css";


function NavbarCustom() {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector(state => state.Auth.user)
    const navigate = useNavigate(); // Initialize the navigate function

    const [toggler, setToggler] = useState({
        menu: false,
        darkMode: false,
    });

    const USER_TYPES = {
        PUBLIC: "",
        MEMBER_USER: "user",
        COORDINATOR_USER: "coordinator",
        ADMIN_USER: "admin",
    };

    // Menu Toggler Function
    const menuToggler = () => {
        let hamburger = document.querySelector('#navbar-sticky-ul');
        if (!toggler.menu) {
            hamburger.style.height = '100vh';
            setToggler(prevState => ({
                ...prevState, menu: !prevState.menu
            }));
        } else if (toggler.menu) {
            hamburger.style.height = '0';
            setToggler(prevState => ({
                ...prevState, menu: !prevState.menu
            }));
        }
    };

    // menuTogglerMobile for mobile and table screen
    const menuTogglerMobile = () => {
        let hamburger = document.querySelector('#navbar-sticky-ul');
        if (hamburger.style.height === '100vh') {
            hamburger.style.height = '0';
            setToggler(prevState => ({
                ...prevState, menu: false
            }));
        }
    };

    // Enable Dark Mode Function
    const enableDarkMode = () => {
        if (!toggler.darkMode) {
            setToggler(prev => ({ ...prev, darkMode: !prev.darkMode }))
            const body = document.querySelector('body');
            body.classList.add('darkMode')
        } else {
            setToggler(prev => ({ ...prev, darkMode: !prev.darkMode }))
            const body = document.querySelector('body');
            body.classList.remove('darkMode')
        }
    }

    // Handle Logout Functionality
    const handleLogout = () => {
        setTimeout(() => {
            // navigate('/', { replace: true });
            navigate('/');
            toast.success("Logout successfully")
        }, 500);
        setTimeout(() => {
            dispatch(setLogout());
        }, 600);
    }

    return (
        <nav className={`sticky top-0 z-30 w-full ulBackground`}>
            <div className="relative max-w-screen-xl flex items-center mx-auto p-4">
                {/* LOGO */}
                <Link to="/" >
                    <div className="relative flex items-center space-x-3 rtl:space-x-reverse z-[11] " >
                        <span className="logoBackShadow absolute"><span>Leicester</span></span>
                        <span id="fmb_Logo" className="w-36">
                            <img src={fmbLogo} alt="fmb_Logo" className="w-full" />
                        </span>
                    </div>
                </Link>

                {/* Menu NavLinks */}
                <div className="navbarUlParent w-full ml-auto">
                    <ul className={`flex flex-col p-4 md:p-0 mt-4 w-full font-medium md:flex-row md:mt-0 linksHover justify-end items-center`} id="navbar-sticky-ul">
                        <div className="mt-16"></div>
                        {// PUBLIC
                            userLoggedIn == null ?
                                <>
                                    <li><NavLink to="/" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><HiHome className="2xl:mr-1 mr-2 text-base" /> Home</NavLink></li>
                                    <li><NavLink className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><IoMail className="2xl:mr-1 mr-2 text-base" /> Contact Us</NavLink></li>
                                    <li><NavLink className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><MdFeedback className="2xl:mr-1 mr-2 text-base" /> Feedback</NavLink></li>
                                </> :
                                // ADMIN USER
                                userLoggedIn.role === USER_TYPES.ADMIN_USER ?
                                    <>
                                        <li><NavLink to="/" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><HiHome className="2xl:mr-1 mr-2 text-base" /> Home</NavLink></li>
                                        <li><NavLink to="/admin" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><FaToolbox className="2xl:mr-1 mr-2 text-base" /> Dashboard</NavLink></li>
                                        <li><NavLink to="/admin/menu_master" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><MdOutlineMenuBook className="2xl:mr-1 mr-2 text-base" /> Menu</NavLink></li>
                                        <li><NavLink to="/admin/coordinator_master" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><BsPersonBadgeFill className="2xl:mr-1 mr-2 text-base" /> Coordinators</NavLink></li>
                                        <li><NavLink to="/admin/area_master" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><PiCellSignalFullBold className="2xl:mr-1 mr-2 text-base" /> Area</NavLink></li>
                                        <li><NavLink to="/admin/member_master" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><MdRememberMe className="2xl:mr-1 mr-2 text-base" /> Member</NavLink></li>
                                    </> :
                                    // COORDINATOR USER
                                    userLoggedIn.role === USER_TYPES.COORDINATOR_USER &&
                                    <>
                                        <li><NavLink to="/" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><HiHome className="2xl:mr-1 mr-2 text-base" /> Home</NavLink></li>
                                        <li><NavLink to="/coordinator" className="py-2 px-3 flex items-center" onClick={menuTogglerMobile}><MdRememberMe className="2xl:mr-1 mr-2 text-base" />Dashboard</NavLink></li>
                                    </>}
                    </ul >
                </div >

                {/* Menu Toggler */}
                < div className="flex gap-4 relative z-10 ml-auto" >
                    {/* Switch Mode Buttons */}
                    < button onClick={enableDarkMode} title="Switch Mode" className={`${toggler.darkMode && 'bg-gray-500'} toggleBtn hamburgerMode min-w-[35px] w-[35px] min-h-[35px] h-auto relative flex justify-center items-center md:order-1 md:space-x-0 rtl:space-x-reverse`}>
                        {
                            toggler.darkMode ?
                                <PiMoonDuotone className="text-[var(--border-lightgray)] cursor-pointer text-xl" /> :
                                <IoSunnyOutline className="text-[var(--border-lightgray)] cursor-pointer text-xl" />
                        }
                    </button >

                    {/* Login & Logout Buttons */}
                    {!userLoggedIn ?

                        // Login Button 
                        <Link className={`${toggler.darkMode ? 'bg-gray-500 text-gray-200' : 'bg-white text-gray-600'} toggleBtn hamburgerMode font-medium px-2 py-1`} to="/login" title="Login">Login</Link> :

                        // Logout Button 
                        <Link className={`${toggler.darkMode ? 'bg-gray-500 text-gray-200' : 'bg-white text-gray-600'} toggleBtn hamburgerMode font-medium px-2 py-1`} onClick={handleLogout} title="Logout">Logout</Link>
                    }

                    <button onClick={menuToggler} className="hamburger min-w-[35px] toggleBtn min-h-[35px] h-auto relative flex items-center md:order-2 md:space-x-0 rtl:space-x-reverse">
                        <RxBorderSolid className={`${!toggler.menu ? 'hamLine1Toggle' : 'hamLine1'} menuTogg hamLine1 cursor-pointer absolute`} />
                        <RxBorderSolid className={`${!toggler.menu ? 'hamLine2Toggle' : 'hamLine2'} menuTogg hamLine2 cursor-pointer absolute`} />
                    </button>
                </div >

            </div >
        </nav >
    );
}
export default memo(NavbarCustom);