import {
    Button,
    Dialog,
    DialogFooter,
    DialogHeader,
    Input,
    Typography,
} from "@material-tailwind/react";
import React, { memo, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { toast } from "sonner";
import { patchRequest } from "../../service/ApiEndpoint";

function AdminCoordinatorEditModal({ coordModal, passData, setCoordModal, setFetchData }) {

    const [open, setOpen] = useState(coordModal);
    const [form, setForm] = useState(passData)

    const handleOpen = () => setCoordModal(!coordModal);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    const isValidPhoneNumber = () => {
        return /^\d{11}$/.test(form.phone);
    };

    const handleSubmit = async (e) => {

        if (!form.name) return toast.error("First enter name");
        if (!form.itsnumber) return toast.error("Enter ITS Number");
        if (!form.phone) return toast.error("Enter Contact Number");

        if (!isValidPhoneNumber()) {
            toast.error("Contact Number must be exactly 11 digits.");
            return;
        }

        try {
            // Sending Data
            const request = await patchRequest(`/user/${form.id}`, {
                name: form.name,
                phone: form.phone,
                role: form.role
            });

            // SUCCESS
            const response = request.data;
            if (response.success) {
                toast.success(response.message);

                // Close Modal
                setTimeout(() => {
                    // Clear Form
                    setForm({
                        name: "",
                        email: "",
                        itsId: "",
                        phone: "",
                        role: ""
                    });
                    setCoordModal(false)

                    // window.location.href = "/admin/coordinator_master";
                }, 800)
            }

            // Catch Error
        } catch (error) {
            // toast.error(error?.message);
            console.log(error);
        }

    }

    return (
        <>
            <button className="hidden"></button>

            <Dialog size="sm" open={open} className="relative p-2">

                {/* CLOSE MODAL BUTTON */}
                <button
                    size="sm"
                    variant="text"
                    className="!absolute right-2 top-2 border border-gray-600 bg-gray-300 hover:bg-gray-400 p-1 rounded-lg"
                    onClick={handleOpen} >
                    <IoCloseSharp className="h-4 w-4 stroke-2 text-gray-600 font-black" />
                </button>

                <DialogHeader className="m-0 block">
                    <Typography variant="h4" color="gray" className="lg:text-xl text-md">
                        Edit Coordinator Details
                    </Typography>
                    <Typography className="mt-1 font-normal text-gray-600 text-sm">
                        Keep your records up-to-date and organized.
                    </Typography>

                </DialogHeader>

                {/* FORM BODY */}
                {/* <DialogBody> */}

                <div className="space-y-4 pb-2 px-4">

                    {/* NAME */}
                    <div>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 text-left font-medium" >
                            Full Name
                        </Typography>
                        <input
                            placeholder="eg. Abdul Jamali"
                            name="name"
                            className="border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-2"
                            value={form.name}
                            onChange={handleChange} />
                    </div>

                    {/* EMAIL */}
                    <div>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 text-left font-medium" >
                            Email Address
                        </Typography>
                        <Input
                            color="gray"
                            size="lg"
                            disabled
                            placeholder="eg. Abdul Jamali"
                            name="email"
                            className="placeholder:opacity-100 focus:!border-t-gray-500 border-t-blue-gray-200"
                            value={form.email}
                        />
                    </div>

                    {/* NUMBER FIELDS */}
                    <div className="flex gap-4 flex-wrap">
                        {/* IST NUMBER */}
                        <div className="w-full">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-2 text-left font-medium"
                            >
                                ITS Number
                            </Typography>
                            <Input
                                color="gray"
                                size="lg"
                                disabled
                                placeholder="eg. 50849637"
                                name="itsId"
                                className="placeholder:opacity-100 focus:!border-t-gray-900"
                                value={form.itsId}
                            />
                        </div>

                        {/* CONTACT */}
                        <div className="w-full">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-2 text-left font-medium focus:!border-t-gray-500 border-t-blue-gray-200"
                            >
                                Contact Number
                            </Typography>
                            <input
                                placeholder="UK 11 Digits"
                                name="phone"
                                className="border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-2"
                                value={form.phone}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    // Allow only digits and prevent input longer than 8 characters
                                    if (/^\d*$/.test(value) && value.length <= 11) {
                                        setForm({ ...form, [e.target.name]: value });
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {/* ROLE */}
                    {/* <div>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 text-left font-medium" >
                            Role
                        </Typography>
                        <select
                            // className="focus:!border-t-gray-500 border-t-gray-blue-200"
                            className="border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-2"
                            name="role"
                            value={form.role}
                            // onChange={(e) => setForm({ ...form, role: e })} // Add a handler to manage changes if needed
                            onChange={handleChange}
                        >
                            <option value="COORDINATOR">COORDINATOR</option>
                            <option value="MEMBER">MEMBER</option>
                        </select>
                    </div> */}

                </div>
                {/* </DialogBody> */}

                {/* SUBMIT BUTTON */}
                <DialogFooter>
                    <Button className="ml-auto bg-gray-500" onClick={handleSubmit}>
                        Update
                    </Button>
                </DialogFooter>

            </Dialog >
        </>
    );
}

export default memo(AdminCoordinatorEditModal);