import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    TabPanel,
    Tabs,
    TabsBody,
    Typography
} from "@material-tailwind/react";
import React, { memo, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { setUser } from "../app/slices/authSlice2";
import { postRequest } from "../service/ApiEndpoint";

function Login() {
    const [type, setType] = useState("card");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Member Login State
    const [form, setForm] = useState({
        email: "",
        password: "",
    })

    const handleSubmit = async (e) => {
        e.preventDefault();

        // TRY
        try {
            const request = await postRequest('/auth/login', {
                email: form.email,
                password: form.password
            })

            const response = request?.data;
            if (response) {

                toast.success("Login successful")
                dispatch(setUser(response))

                if (response.role === 'admin') {
                    navigate('/admin');
                }
                else if (response.role === 'COORDINATOR') {
                    navigate('/coordinator');
                }
                else if (response.role === 'user') {
                    navigate('/member');
                } else {
                    navigate("/");
                }

                // Success now Clear form
                setForm({
                    email: "",
                    password: "",
                })
            }

            // Catch Error
        } catch (error) {
            // toast.error(error?.message)
            console.log(error);
        }
    }

    return (
        <div className="cus-container mx-auto px-2 py-8 flex justify-center">
            <Card className="w-full sm:max-w-[24rem]">
                <CardHeader
                    color="gray"
                    floated={false}
                    shadow={false}
                    className="m-0 grid place-items-center px-4 py-8 text-center"
                >
                    <div className="mb-4 h-20 p-6 text-white">
                        {/* {type === "card" ? ( */}
                        <>
                            <FaUsers className="h-10 w-10 text-white mx-auto" />
                            <Typography variant="h5" color="white" className="mx-auto">
                                Existing User LOGIN
                            </Typography>
                        </>
                        {/* ) : (
                            <>
                                <FaUserPlus className="h-10 w-10 text-white mx-auto" />
                                <Typography variant="h5" color="white" className="mx-auto">
                                    Register as New User
                                </Typography>
                            </>
                        )} */}
                    </div>
                </CardHeader>
                <CardBody>
                    <Tabs value={type} className="overflow-visible">
                        {/* <TabsHeader className="relative z-0 ">
                            <Tab value="card" onClick={() => setType("card")}>
                                Existing User
                            </Tab>
                            <Tab value="paypal" onClick={() => navigate("/register")}>
                                New User
                            </Tab>
                        </TabsHeader> */}
                        <TabsBody className="!overflow-x-hidden !overflow-y-visible"
                            animate={{
                                initial:
                                    { x: type === "card" ? 400 : -400, },
                                mount: { x: 0, },
                                unmount: { x: type === "card" ? 400 : -400, },
                            }}
                        >

                            {/* Member Login */}
                            <TabPanel value="card" className="p-0 w-full">
                                <Card className="mx-auto w-full max-w-[24rem]" shadow={false}>
                                    <form onSubmit={handleSubmit}>

                                        <CardBody className="flex flex-col gap-4">
                                            <Typography
                                                className="text-xs mb-1 font-normal"
                                                variant="paragraph"
                                                color="gray"
                                            >
                                                Enter your email and password to Log In.
                                            </Typography>
                                            <Typography className="-mb-2 flex items-center gap-1" variant="h6">
                                                <MdEmail /> Email
                                            </Typography>
                                            <Input label="Enter your email address" size="lg" value={form.email} name="email" type="email" onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} />
                                            <Typography className="-mb-2 flex items-center gap-1" variant="h6">
                                                <RiLockPasswordFill /> Password
                                            </Typography>
                                            <Input label="Enter your password" size="lg" value={form.password} name="password" type="password" onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} />
                                        </CardBody>
                                        <CardFooter className="pt-0">
                                            <Button className="bg-gray-500"
                                                type="submit"
                                                // onClick={handleOnSubmitLogin}
                                                fullWidth>
                                                Log In
                                            </Button>
                                            <Typography variant="small" className="mt-4 flex justify-center">
                                                Dont't have account ?
                                                <Typography
                                                    as="a"
                                                    href="/register"
                                                    variant="small"
                                                    // color="blue-gray"
                                                    className="ml-1 font-bold"
                                                >
                                                    New User
                                                </Typography>
                                            </Typography>
                                        </CardFooter>
                                    </form>
                                </Card>
                            </TabPanel>

                            {/* Coordinator Login */}
                            {/* <TabPanel value="paypal" className="p-0"></TabPanel> */}
                        </TabsBody>
                    </Tabs>
                </CardBody>
            </Card>
        </div>
    )
}

export default memo(Login);