import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMonthEvents } from '../../app/slices/eventsSlice';
import { getRequest } from '../../service/ApiEndpoint';
import EditorWithDialog from '../WYSIWYG_Editor/EditorWithDialog';
import "./Calendar.css";

function Calendar() {
    const [currentDate, setCurrentDate] = useState("");
    const [eventId, setEventId] = useState(null);
    const [dailogOpen, setDailogOpen] = useState(false);
    const [JSXValue, setJSXValue] = useState("");
    const dispatch = useDispatch();
    const eventSelector = useSelector(state => state.calendarEvents.monthEvents)
    // Ref to store cached events from localStorage
    const monthEvents = useRef(JSON.parse(localStorage.getItem("monthEvents")) || null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // Fetch latest events from the API
                // const request = await getRequest(`/event/month`, {});
                const request = await getRequest(`/event`, {});
                const response = request?.data;
                const filterEvents = response?.map(el => {
                    return {
                        id: el.id,
                        title: el.title,
                        description: el.description,
                        start: el.start.slice(0, 10),
                        end: el.end.slice(0, 10),
                    }
                })

                // If the fetched response is different from the cached one
                if (JSON.stringify(response) !== JSON.stringify(monthEvents.current)) {
                    // Update cache and Redux store with fresh data
                    monthEvents.current = response;  // Cache in useRef

                    // localStorage.setItem("monthEvents", JSON.stringify(response));  // Cache in localStorage
                    // dispatch(updateMonthEvents(response));  // Update Redux store

                    monthEvents.current = filterEvents;  // Cache in useRef
                    localStorage.setItem("monthEvents", JSON.stringify(filterEvents));  // Cache in localStorage
                    dispatch(updateMonthEvents(filterEvents));  // Update Redux store
                } else {
                    // console.log('Using cached data, no changes detected');
                }
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };

        // Immediately dispatch cached data if available
        if (monthEvents.current) {
            dispatch(updateMonthEvents(monthEvents.current));  // Use cached events from useRef
        }
        // Fetch new data from the API regardless of cache
        fetchEvents();
    }, [dispatch]);

    // Memoized Function
    const renderEventContent = useCallback((eventInfo) => {
        return (<div dangerouslySetInnerHTML={{ __html: eventInfo.event.extendedProps.description }} />);
    }, []);

    // Date Click
    const handleDateClick = (info) => {
        // Will set the date
        setCurrentDate(info.dateStr);
        setDailogOpen(!dailogOpen);
    };

    useEffect(() => {
        if (!currentDate) return;
        const getEvents = async () => {
            try {
                const request = await getRequest(`event/date/${currentDate}`, {})
                const response = request?.data;
                setJSXValue(response[0]?.description)
                setEventId(response[0]?.id);
            } catch (error) {
                console.log(error);
            }
        }
        getEvents();
    }, [currentDate])

    const handleOpenPushMenu = () => { window.location.href = "/admin/push-menu" }

    return (
        <div className='cus-container mx-auto bg-white p-4 rounded-3xl border-2 border-gray-400 shadow-2xl mt-14 '>
            <div className='font-black my-2 base-text flex justify-between items-center'>
                <h3 style={{ fontVariant: 'small-caps' }}>Thaali Calendar</h3>
                <button className='bg-white text-gray-600 toggleBtn hamburgerMode font-medium px-2 py-1' title='Push Next Week Menu' onClick={handleOpenPushMenu}>Push Menu</button>
            </div>

            <EditorWithDialog events={monthEvents} eventId={eventId} setEventId={setEventId} currentDate={currentDate} setCurrentDate={setCurrentDate} JSXValue={JSXValue} setJSXValue={setJSXValue} eventSelector={eventSelector} dailogOpen={dailogOpen} setDailogOpen={setDailogOpen} />

            {/* Full Calendar */}
            <div className='bg-gray-600 rounded-xl px-4 py-6 text-media shadow-2xl text-white text-xl' style={{ fontVariant: 'small-caps' }}>
                <FullCalendar
                    // plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                    // dayGridMonth,timeGridWeek,timeGridDay
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                    initialView={'dayGridMonth'}
                    headerToolbar={{
                        start: 'today,dayGridMonth,listWeek',
                        center: "title",
                        end: "prev,next",
                        // right: 'dayGridMonth,listWeek',
                    }}
                    events={eventSelector}
                    eventContent={renderEventContent}
                    dateClick={handleDateClick}
                />
            </div>
        </div >
    )
}

export default memo(Calendar)