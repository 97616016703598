import { createSlice } from "@reduxjs/toolkit";
import { postLogout } from "../../service/ApiEndpoint";

const initialState = {
  loading: null,
  error: null,
  user: null,
  token: null, // Add token to the initial state
  coordinators: [],
  members: [],
  areas: [],
};

// SLICE
const authSlice2 = createSlice({
  name: "authSlice",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload; // Set the token
    },
    removeToken: (state) => {
      state.token = null; // Remove the token
    },
    setMembers: (state, action) => {
      state.members = action.payload;
    },
    setCoordinators: (state, action) => {
      state.coordinators = action.payload;
    },
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
    setLogout: async (state) => {
      try {
        // Request
        const request = await postLogout("/auth/logout", {});
        const response = request.success;
        if (response) {
          state.user = null;
          state.token = null; // Clear the token on logout
          state.loading = null;
          state.error = null;
          state.coordinators = [];
          state.members = [];
          state.areas = [];
        }
        // Catch Error
      } catch (error) {
        console.error(error?.response?.data?.message);
      }
    },
  },
});

export const {
  setUser,
  setToken,
  removeToken,
  setMembers,
  setCoordinators,
  setAreas,
  setLogout,
} = authSlice2.actions;
export default authSlice2.reducer;
