import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';
import { MdDeleteOutline } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { setAreas } from '../../../app/slices/authSlice2.js';
import ButtonAnimated from '../../../components/comman/Button/AnimatedButton/ButtonAnimated';
import ToggleButtonAnimated from '../../../components/comman/Button/AnimatedButton/ToggleButtonAnimated';
import LabelComponent from '../../../components/comman/Label/LabelComponent';
import AdminAreaEditModal from "../../../components/ModalForms/AdminAreaEditModal.jsx";
import { capitalizeWords } from '../../../extraStuff.js';
import { deleteReq, getRequest } from '../../../service/ApiEndpoint';


function AdminAreas() {
    const tableHead = ["S.No.", "Area Name", "Coordinator Name", "Action"]
    const [fetchData, setFetchData] = useState([]);
    const [fetchCoord, setFetchCoord] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const [areaModal, setAreaModal] = useState(false)
    const [passData, setPassData] = useState({})


    useEffect(() => {
        // GET All Areas
        const getAllUsers = async () => {
            try {

                // Request
                const request = await getRequest('/area', {})
                const request2 = await getRequest('user?role=coordinator', {});
                const response = request?.data;
                const response2 = request2?.data;

                setFetchData(response);
                setFetchCoord(response2)
                dispatch(setAreas(response));

                // Catch Error
            } catch (error) {
                // toast.error(error?.response?.data?.message)
                console.log(error);
            }
        }

        getAllUsers();
    }, [dispatch])

    const handleRegister = () => {
        navigate("/admin/register/area");
    }

    const handleDelete = useCallback(async (item) => {

        try {
            // Sending Data
            const response = await deleteReq(`/area/${Number(item)}`);

            // SUCCESS
            if (response) {
                // Display toast notification
                toast.success("Area Deleted Successfully");

                // Update the state to remove the deleted item
                setFetchData(prevData => prevData.filter(area => area.id !== Number(item)));

                // Close the modal and navigate after a brief delay
                setTimeout(() => {
                    navigate("/admin/area_master");
                }, 100);
            }

            // Catch Error
        } catch (error) {
            // toast.error(error?.message);
            console.log(error);
        }
    }, [navigate])

    // CONFIRM BEFORE DELETE AREA
    const handleOpenConfirm = (id) => {
        setDeleteId(id);
        setIsConfirmOpen(true);
    };

    const handleDeleteConfirmed = () => {
        handleDelete(deleteId);
        setIsConfirmOpen(false);
    };

    return (
        <div className='px-4'>
            <div className='max-w-screen-xl mt-14 mx-auto bg-white py-2 rounded-xl shadow-xl'>

                {areaModal &&
                    <AdminAreaEditModal areaModal={areaModal} passData={passData} setAreaModal={setAreaModal} />
                }
                <LabelComponent labelNameClass={'text-3xl font-medium text-pri mx-4'} labelName={'Areas'} />

                <div className='motif-central'>&nbsp;</div>
                <ButtonAnimated btnIcon={<IoPersonAddSharp className='text-xl' />} btnName={'Add Area'}
                    btnOnClick={handleRegister}
                />

                <div className="relative overflow-x-auto shadow-md rounded-lg my-4 border-2 border-sec mx-2 bg-white">
                    {/* TABLE */}
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        {/* TABLE HEAD */}
                        <thead className="text-xs uppercase bg-sec text-sec">
                            <tr>
                                {tableHead.map((headItem, idx) => <th scope="col" className="pl-2 px-1 py-3 font-semibold" key={idx}>
                                    {headItem}
                                </th>)}
                            </tr>
                        </thead>
                        {/* TABLE BODY */}
                        <tbody>
                            {fetchData?.map((el, idx) =>
                                <tr className='hover:bg-[--table-bg-hoverBlue]' key={el?.id}>
                                    <th scope="row" className="px-1 py-1 font-medium base-text whitespace-nowrap dark:text-gray-900 mx-2 pl-2">
                                        {idx + 1}
                                    </th>
                                    <td className="pl-2 py-1 base-text">
                                        {capitalizeWords(el?.name)}
                                    </td>
                                    <td className="pl-2 py-1 base-text">
                                        {fetchCoord && fetchCoord.find(item => item.id === el.coordinatorId) ? capitalizeWords(fetchCoord.find(item => item.id === el.coordinatorId).name) : ''}
                                    </td>
                                    <td className="py-2 flex items-center">
                                        <ToggleButtonAnimated btnIcon={
                                            <TbEdit className='text-green-600 cursor-pointer text-xl' />
                                        } onBtnClick={() => { setPassData(el); setAreaModal(!areaModal) }} />
                                        <ToggleButtonAnimated btnIcon={
                                            < MdDeleteOutline className='text-red-600 cursor-pointer text-xl' />
                                        } onBtnClick={() => handleOpenConfirm(el.id)} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <LabelComponent labelName={`Displaying ${fetchData?.length} records`} labelNameClass={'m-2 text-xs base-text'} />


                {/* Confirmation Dailog Modal */}

                <Dialog open={isConfirmOpen} handler={() => setIsConfirmOpen(!isConfirmOpen)}>
                    <DialogHeader>Confirm Deletion</DialogHeader>
                    <DialogBody>
                        Are you sure you want to delete the Area?
                    </DialogBody>
                    <DialogFooter className='flex gap-2'>
                        <Button variant="text" color="red" onClick={() => setIsConfirmOpen(false)}>
                            Cancel
                        </Button>
                        <Button variant="gradient" color="green" onClick={handleDeleteConfirmed}>
                            Confirm
                        </Button>
                    </DialogFooter>
                </Dialog>
            </div >
        </div>
    )
}

export default memo(AdminAreas)