export function capitalizeWords(str = "none") {
  return str
    .split(" ") // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the array back into a single string
}

// export const dayName = ["mon", "tue", "wed", "thur", "fri", "sat", "sun"];
export const dayName = [
  "Monday",
  "Tuesday",
  "Wednesay",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// Date

// export function todayDate() {
//   const today = new Date();
//   const month = today.getMonth() + 1;
//   const year = today.getFullYear();
//   const todayDate = today.toString().split(" ");
//   return todayDate;
// }

// const evn = [
// {
//   title: "Menu",
//   description: `<ul style="overflow: auto; height: auto; min-height: 50px; min-height: 50px; max-height: 150px">
//             <li>Urs Syedi Hasanfeer Shaheed AQ</li>
//         </ul>`,
//   start: "2024-07-29",
//   end: "2024-07-29",
// },
// {
//   title: "Menu",
//   description: `<ul style="overflow: auto; height: auto; min-height: 50px; max-height: 150px">
//             <li>Urs Syedi Dada Sulemanji</li>
//         </ul>`,
//   start: "2024-07-30",
//   end: "2024-07-30",
// },
// {
//   title: "Menu",
//   description: `<ul style="overflow: auto; height: auto; min-height: 50px; max-height: 150px">
//             <li>Urs Syedi Fakhruddin Shaheed AQ</li>
//         </ul>`,
//   start: "2024-08-02",
//   end: "2024-08-02",
// },
// {
//   title: "Menu",
//   description: `<ul style="overflow: auto; height: auto; min-height: 50px; max-height: 150px">
//                 <li>✦Urs Syedi Tayyeb bs Zainudin bin Syedna Mohammed Burhanuddin AQ</li>
//                 <li>✦ Masoor Daal</li>
//                 <li>✦ Roti</li>
//                 <li>✦ Salan Paak</li>
//             </ul>`,
//   start: "2024-08-14",
//   end: "2024-08-14",
// },
// {
//   title: "Menu",
//   description: `
//         <ul style="overflow: auto; height: auto; min-height: 50px; max-height: 150px">
//             <li>🇮🇳 Independence Day of India</li>
//             <li>・Gosht</li>
//             <li>・Roti</li>
//             <li>・Khopra Paak</li>
//         </ul>`,
//   start: "2024-08-15",
//   end: "2024-08-15",
// },
// {
//   title: "Menu",
//   description: `
//         <ul style="overflow: auto; height: auto; min-height: 50px; max-height: 150px">
//             <li>・Manchurian Rice</li>
//             <li>・Soup</li>
//             <li>・Ice Cream</li>
//         </ul>`,
//   start: "2024-08-16",
//   end: "2024-08-16",
// },
// {
//   title: "Menu",
//   description: `<ul style="overflow: auto; height: auto; min-height: 50px; max-height: 150px">
//             <li>・Urs Sydena Ahmed Hamiduddin Kirmani RA</li>
//             <li>・Butter Chicken</li>
//             <li>・Paratha</li>
//             <li>・Rice</li>
//             </ul>`,
//   start: "2024-08-17",
//   end: "2024-08-17",
// },
// {
//   title: "Menu",
//   description: `<ul style="overflow: auto; height: auto; min-height: 50px; max-height: 150px">
//             <li>Chehlum Imam Husain SA || لبيكَ ياحُسين</li>
//         </ul>`,
//   start: "2024-08-25",
//   end: "2024-08-25",
// },
// ];
