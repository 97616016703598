import { Typography } from "@material-tailwind/react";
import React, { memo, useEffect, useState } from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { useSelector } from "react-redux";
import dp from "../../assets/images/dp.png";
import its from "../../assets/images/its_icon.png";
import thaaliIcon from "../../assets/images/thaali-icon.png";
import { capitalizeWords } from "../../extraStuff";
import { getRequest } from "../../service/ApiEndpoint";

const CoordinatorDashboard = () => {
  const userInfo = useSelector((state) => state.Auth.user)
  const [fetchData, setFetchData] = useState()

  useEffect(() => {
    // GET All Users
    const getAllUsers = async () => {
      try {
        // Request
        const request = await getRequest(`/area`, {})
        const response = request.data;
        setFetchData(response)

        // Catch Error
      } catch (error) {
        // toast.error(error?.message)
        console.log(error);
      }
    }
    getAllUsers();

  }, [])

  return (
    <div className="flex flex-col md:flex-row gap-6 p-6 bg-gray-100 w-full">

      {/* CONTACT INFORMATION */}
      <div className="w-full bg-white rounded-lg shadow-md p-6 flex flex-row flex-wrap justify-evenly">
        <div className="w-auto max-w-[250px] h-auto flex items-center">
          <img src={dp} alt='profile_picture' className="w-full h-[250px] rounded-lg border-2 border-gray-100 shadow-xl" />
        </div>
        <div>
          <Typography className="text-md mt-6 mb-4 tracking-widest uppercase text-gray-500 font-bold"> Contact Information</Typography>
          <div className="flex gap-1.5 items-center mb-2">
            <FaRegAddressCard className="text-sm" />
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium" >
              Name :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {userInfo?.name}
            </Typography>
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            <MdOutlineMail className="text-sm" />
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              Email :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {userInfo?.email}
            </Typography>
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            <FiPhone className="text-sm" />
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              Contact Number :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              <a href={userInfo?.phone}>{userInfo?.phone}</a>
            </Typography>
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            {/* <BiWallet className="text-sm" /> */}
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              <img src={its} alt="" width='14px' />
              ITS Number :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {userInfo?.itsId}
            </Typography>
          </div>

          {/* BASIC INFORMATION */}
          <Typography className="text-xs mt-6 mb-4 tracking-widest uppercase text-gray-500 font-bold"> Basic Information</Typography>
          <div className="flex gap-1.5 items-center mb-2">
            <FaRegCircleUser className="text-sm" />
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              Registered :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {userInfo?.role === 'coordinator' && capitalizeWords('COORDINATOR')}
            </Typography>
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              <img src={thaaliIcon} alt="" width='14px' />Area Name :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {fetchData?.map(item => item.id === userInfo?.areaId && capitalizeWords(item.name))}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CoordinatorDashboard);