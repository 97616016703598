import { XMarkIcon } from "@heroicons/react/24/outline";
import {
    Button,
    Dialog,
    DialogFooter,
    DialogHeader,
    IconButton,
    Typography
} from "@material-tailwind/react";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { getRequest, postRequest } from "../../service/ApiEndpoint";
import DatePicker from "../comman/DatePicker/DatePicker";
import MaterialTable from "../comman/Tabel/MaterialTable";

function PushMenuPage() {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const [selectedDate, setSelectedDate] = useState("");
    const [onlyNext7Days, setOnlyNext7Days] = useState(true);
    const [data, setData] = useState([]);
    const [selectedMenus, setSelectedMenus] = useState({ newDates: [] });
    const [pushBtn, setPushBtn] = useState({ disa: true, name: "submit" });

    const fetchUpComingWeekMenu = useCallback(async () => {
        try {
            const request = await getRequest(`/event/upcoming/${selectedDate}`, {});
            const response = request?.data;
            setData(response);
        } catch (error) {
            console.log(error);
        }
        // Add selectedDate as a dependency
    }, [selectedDate]);

    const fetchMenuFromDate = useCallback(async () => {
        try {
            const request = await getRequest(`/event/upcoming`, {});
            const response = request?.data;
            setData(response);
        } catch (error) {
            console.log(error);
        }
        // No dependencies as it always fetches from the same endpoint
    }, []);

    const fetchOnlyFor7Days = useCallback(async () => {
        try {
            const request = await getRequest(`/event/upcomingweek`, {});
            const response = request?.data;
            setData(response);
        } catch (error) {
            console.log(error);
        }
        // No dependencies as it always fetches for 7 days from the same endpoint
    }, []);

    const fetchOnlyFor7DaysFromSelectedDate = useCallback(async () => {
        try {
            const request = await getRequest(`event/upcomingweek/${selectedDate}`, {});
            const response = request?.data;
            setData(response);
        } catch (error) {
            console.log(error);
        }
        // Add selectedDate as a dependency
    }, [selectedDate]);

    const memoizedHandleShow = useMemo(() => {
        return () => {
            if (selectedDate && onlyNext7Days) {
                fetchOnlyFor7DaysFromSelectedDate();
            } else if (selectedDate && !onlyNext7Days) {
                fetchUpComingWeekMenu();
            } else if (onlyNext7Days) {
                fetchOnlyFor7Days();
            } else {
                fetchMenuFromDate();
            }
        };
    }, [selectedDate, onlyNext7Days, fetchOnlyFor7DaysFromSelectedDate, fetchUpComingWeekMenu, fetchOnlyFor7Days, fetchMenuFromDate]);


    const checkDateInDatabase = async (date) => {
        try {
            const response = await getRequest(`/event/check-date/${date}`, {}); // Assuming the API endpoint is /event/check-date/:date
            if (response?.data?.message === 'Success') {
                toast.warning(`${date.slice(0, 10)} already pushed`)
            }
            return response?.data?.data?.length > 0; // Return true if the date exists in the database
        } catch (error) {
            console.error(`Error checking date ${date}:`, error);
            return false;
        }
    };

    const handleSubmit = async () => {
        setPushBtn({ disa: true, name: "Loading..." });
        const datesToCheck = [...selectedMenus.newDates];

        // Filter out dates that are already in the database
        const selectedMenuDates = [];

        for (const date of datesToCheck) {
            const isDateInDb = await checkDateInDatabase(date);

            if (!isDateInDb) {
                selectedMenuDates.push(date); // Only keep dates that are NOT in the database
            }
        }

        // Update the selectedMenuDates state with only the new dates
        setSelectedMenus((prevSelected) => ({
            ...prevSelected,
            newDates: selectedMenuDates,
        }));

        // Continue with your submit logic, e.g., sending newDates to the backend
        const sendNewUserDateEvents = async () => {
            if (selectedMenuDates.length < 1) {
                setTimeout(() => {
                    toast.error("Try selecting new dates");
                    setPushBtn({ disa: true, name: "error" });
                }, 3000);
                setTimeout(() => {
                    setSelectedMenus({ newDates: [] })
                    handleClose();
                }, 5000);
                return false;
            }
            try {
                const response = await postRequest(`/event/notifications`, { selectedMenuDates });
                if (response?.data?.message === "Success") {
                    setTimeout(() => {
                        toast.success(response?.data?.message)
                        setPushBtn({ disa: true, name: "completed" });
                    }, 3000);
                    setTimeout(() => {
                        setSelectedMenus({ newDates: [] })
                        handleClose();
                    }, 5000);
                }
                return response?.data?.data?.length > 0; // Return true if the date exists in the database
            } catch (error) {
                setPushBtn({ disa: true, name: "error" });
                toast.error("Network Error Try Again");
                setTimeout(() => {
                    setSelectedMenus({ newDates: [] })
                    handleClose();
                }, 1500);
            }
        }
        sendNewUserDateEvents();
    };

    const handleClose = () => { navigate("/admin/menu_master") }

    return (
        <>
            <Button onClick={handleOpen} variant="gradient" className="hidden">
                Select Date
            </Button>
            <Dialog size="sm" open={open} handler={handleOpen} className="p-4 faltuH1 overflow-auto">
                <DialogHeader className="relative m-0 block overflow-y-auto">
                    <DatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                    <div className="flex items-center my-2 gap-2">
                        <input type="checkbox" id='check7Days' checked={onlyNext7Days} onChange={() => setOnlyNext7Days(!onlyNext7Days)} />
                        <label htmlFor="check7Days" className="text-xs">Want 7 days menu from today?</label>
                    </div>
                    <DialogFooter className="p-0 block">
                        <button className="bg-gray-500 text-white text-xs font-bold uppercase shadow-md align-middle text-center py-1 px-2 rounded-md cursor-pointer"
                            onClick={memoizedHandleShow}>
                            Show Menu
                        </button>
                    </DialogFooter>
                    <IconButton
                        size="sm"
                        variant="text"
                        className="!absolute right-0 -top-1"
                        onClick={handleClose}>
                        <XMarkIcon className="h-6 w-6 stroke-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600" />
                    </IconButton>
                </DialogHeader>
                <div className="px-3">
                    <MaterialTable data={data} selectedMenus={selectedMenus} setSelectedMenus={setSelectedMenus} pushBtn={pushBtn} setPushBtn={setPushBtn} />
                </div>

                {/* Displaying Records */}
                {data && data.length > 0 &&
                    <div>
                        <Typography className='text-xs pl-4 text-gray-500 my-1 labelFontStyle'>
                            {`Displaying ${data.length} records`}
                        </Typography>
                        <Typography className="pl-4  font-normal text-red-500 text-xs">
                            Selected dates will be pushed only once
                        </Typography>
                    </div>
                }
                <DialogFooter>
                    <Button
                        // disabled={selectedMenus.newDates.length > 0 ? false : true}
                        disabled={pushBtn.disa}
                        className={`ml-auto ${pushBtn.name === 'completed' ? 'bg-green-500' : pushBtn.name === 'error' ? 'bg-red-500' : 'bg-gray-500'}`} onClick={handleSubmit}>
                        {pushBtn.name}
                    </Button>
                </DialogFooter>
            </Dialog >
        </>
    );
}

export default memo(PushMenuPage)