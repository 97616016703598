import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import NavbarCustom from '../../components/Navbar/NavbarCustom';


const AdminProtectedLayout = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.user)

  useEffect(() => {

    if (!user || user.role !== 'admin') {
      setTimeout(() => {
        navigate("/"); // Redirect to the login page
      }, 1);
    }
  }, [user, navigate]);

  return (
    <section className='relative'>
      <NavbarCustom />
      <Outlet />
    </section>
  )
};

export default memo(AdminProtectedLayout);
