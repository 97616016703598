import React, { memo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MyEditor = ({ existingVal, setJSXValue }) => {
  const quillRef = useRef(null);

  const handleChange = (value) => {
    setJSXValue(value);
  };

  return (
    <div>
      <ReactQuill ref={quillRef} value={existingVal} onChange={handleChange} />
    </div>
  );
};

export default memo(MyEditor);
