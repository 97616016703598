import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dp from "../../assets/images/dp.png";
import its from "../../assets/images/its_icon.png";
import thaaliIcon from "../../assets/images/thaali-icon.png";
import { dayName } from "../../extraStuff";
import { getRequest } from "../../service/ApiEndpoint";

const UserInfoDashboard = () => {
  const [fetchDays, setFetchDays] = useState([]);
  const userInfo = useSelector((state) => state.Auth.user)

  // const dayStatus = defaultDays?.[0];

  // GET User Days
  useEffect(() => {
    const getAllAreas = async () => {
      try {
        // Request
        const request = await getRequest(`/user/days/${userInfo?.id}`, {});
        const response = request?.data;
        setFetchDays(response);

        // Catch Error
      } catch (error) {
        // toast.error(error?.message);
        console.log(error);
      }
    };

    getAllAreas();
  }, []);

  function capitalizeWords(str = "none") {
    return str
      .split(" ") // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the array back into a single string
  }


  return (
    <div className="flex flex-col md:flex-row gap-6 p-6 bg-gray-100 w-full">
      {/* CONTACT INFORMATION */}
      <div className="w-full bg-white rounded-lg shadow-md p-6 flex flex-row flex-wrap gap-4 md:justify-between items-center">
        <div className="w-auto max-w-[250px] h-auto flex items-center">
          <img src={dp} alt='profile_picture' className="w-full h-[250px] rounded-lg border-2 border-gray-100 shadow-xl" />
        </div>
        <div>
          <Typography className="text-md mt-6 mb-4 tracking-widest uppercase text-gray-500 font-bold"> Contact Information</Typography>
          <div className="flex gap-1.5 items-center mb-2">
            <FaRegAddressCard className="text-sm" />
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium" >
              Name :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {capitalizeWords(userInfo?.name)}
            </Typography>
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            <MdOutlineMail className="text-sm" />
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              Email :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {userInfo?.email}
            </Typography>
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            <FiPhone className="text-sm" />
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              Contact Number :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              <a href={userInfo?.phone}>{userInfo?.phone}</a>
            </Typography>
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            {/* <BiWallet className="text-sm" /> */}
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              <img src={its} alt="" width='14px' />
              ITS Number :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {userInfo?.itsId}
            </Typography>
          </div>

          {/* BASIC INFORMATION */}
          <Typography className="text-xs mt-6 mb-4 tracking-widest uppercase text-gray-500 font-bold"> Basic Information</Typography>
          <div className="flex gap-1.5 items-center mb-2">
            <FaRegCircleUser className="text-sm" />
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              Registered :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {userInfo?.role === 'user' && 'MEMBER'}
            </Typography>
          </div>
          <div className="flex gap-1.5 items-center mb-2">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-medium flex items-center gap-2" >
              <img src={thaaliIcon} alt="" width='14px' />Thaali Size :
            </Typography>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-thin" >
              {capitalizeWords(userInfo?.size)}

            </Typography>
          </div>
        </div>
        <div>
          <div className="flex items-center gap-4">
            <Typography className="text-md mt-6 mb-4 tracking-widest uppercase text-gray-500 font-bold">
              <span>Default Days</span>
            </Typography>
            <Link to='/member/days' className="text-blue-500 underline text-sm">Edit</Link>
          </div>
          {fetchDays?.map((item, idx) =>
            <div className="flex justify-between items-center mb-2">
              <Typography
                variant="small"
                color="blue-gray"
                className=" font-medium" >
                {/* <img src={mon} alt="monday" width='28' /> */}
                {dayName[item.day]}
              </Typography>
              <Typography
                variant="h4"
                className='border border-gray-500 rounded-lg'>
                {item.active === 1 ? <TiTick className="text-green-500" /> : <RxCross2 className="text-red-500 font-black" />}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInfoDashboard;