import {
    Button,
    Dialog,
    DialogFooter,
    DialogHeader,
    Typography,
} from "@material-tailwind/react";
import React, { memo, useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { setCoordinators } from "../../app/slices/authSlice2";
import { capitalizeWords } from "../../extraStuff";
import { getRequest, putRequest } from "../../service/ApiEndpoint";

function AdminAreaEditModal({ areaModal, passData, setAreaModal }) {
    const [open, setOpen] = useState(areaModal);
    const [form, setForm] = useState(passData)
    const dispatch = useDispatch()
    const coordSelector = useSelector(state => state.Auth.coordinators);

    const handleOpen = () => setAreaModal(!areaModal);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    const handleSubmit = async (e) => {
        console.log(e);

        if (!form.name) return toast.error("First enter area name");
        if (!form.coordinatorId) return toast.error("Select area coordinator");

        try {
            // Sending Data
            const response = await putRequest(`/area/${form.id}`, {
                name: form.name.toLowerCase(),
                coordinatorId: Number(form.coordinatorId),
            });

            // SUCCESS
            if (response) {
                toast.success("Area updated successfully");
                // Clear Form
                setForm({
                    name: "", coordinatorId: ""
                });
                setTimeout(() => {
                    window.location.href = "/admin/area_master";
                }, 800);
            }

            // Catch Error
        } catch (error) {
            // toast.error(error?.message);
            console.log(error);
        }

    }

    useEffect(() => {
        // GET All Areas
        const getAllUsers = async () => {
            try {
                // Request
                const request = await getRequest('/user?role=coordinator', {})
                const response = request.data;
                dispatch(setCoordinators(response));
                // Catch Error
            } catch (error) {
                // toast.error(error?.message)
                console.log(error);
            }
        }

        getAllUsers();
    }, [dispatch])

    return (
        <>
            <button className="hidden"></button>

            <Dialog size="sm" open={open} className="relative p-2">

                {/* CLOSE MODAL BUTTON */}
                <button
                    size="sm"
                    variant="text"
                    className="!absolute right-2 top-2 border border-gray-600 bg-gray-300 hover:bg-gray-400 p-1 rounded-lg"
                    onClick={handleOpen} >
                    <IoCloseSharp className="h-4 w-4 stroke-2 text-gray-600 font-black" />
                </button>

                <DialogHeader className="m-0 block">
                    <Typography variant="h4" color="gray" className="lg:text-xl text-md">
                        Edit Area Details
                    </Typography>
                </DialogHeader>

                {/* FORM BODY */}
                <div className="space-y-4 pb-2 px-4">
                    {/* NAME */}
                    <div>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 text-left font-medium" >
                            Area Name
                        </Typography>
                        <input
                            placeholder="eg. Abdul Jamali"
                            name="name"
                            className="border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-2"
                            value={capitalizeWords(form.name)}
                            onChange={handleChange} />
                    </div>

                    {/* ROLE */}
                    <div>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 text-left font-medium" >
                            Coordinator Name
                        </Typography>
                        <select
                            name="coordinatorId"
                            value={form.coordinatorId}
                            onChange={(e) => setForm({ ...form, coordinatorId: e.target.value })}
                            className="border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full px-4 py-2">
                            {coordSelector?.map(item => (item.id !== form.coordinatorId ?
                                <option value={item.id} key={item.id}>{capitalizeWords(item.name)}</option> :
                                <option disabled value={item.id} key={item.id}>{capitalizeWords(item.name)}</option>))}
                        </select>
                    </div>

                </div>
                {/* </DialogBody> */}

                {/* SUBMIT BUTTON */}
                <DialogFooter>
                    <Button className="ml-auto bg-gray-500" onClick={handleSubmit}>
                        Update
                    </Button>
                </DialogFooter>

            </Dialog >
        </>
    );
}

export default memo(AdminAreaEditModal);