import {
    Bars2Icon,
    ChartPieIcon,
    ChevronDownIcon,
    // CodeBracketSquareIcon,
    Cog6ToothIcon,
    CubeTransparentIcon,
    HomeIcon,
    InboxArrowDownIcon,
    LifebuoyIcon,
    PowerIcon,
    // RocketLaunchIcon,
    // Square3Stack3DIcon,
    UserCircleIcon
} from "@heroicons/react/24/solid";
import {
    Avatar,
    Button,
    // Card,
    IconButton,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    MobileNav,
    Navbar,
    Typography
} from "@material-tailwind/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { setLogout } from "../../app/slices/authSlice2";
import fmbLogo from "../../assets/images/fmb_logo.png";
import "./Navbar2.css";

// profile menu component
const profileMenuItems = [
    {
        label: "My Profile",
        url: "/member/profile",
        icon: UserCircleIcon,
    },
    {
        label: "Default Days",
        url: "/member/days",
        icon: Cog6ToothIcon,
    },
    {
        label: "Inbox",
        url: "",
        icon: InboxArrowDownIcon,
    },
    {
        label: "Help",
        url: "",
        icon: LifebuoyIcon,
    },
    {
        label: "Log Out",
        url: "/",
        icon: PowerIcon,
    },
];

function ProfileMenu() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const user = useSelector(state => state.Auth.user);
    const closeMenu = () => setIsMenuOpen(false);
    const dispatch = useDispatch();
    // const userLoggedIn = useSelector(state => state.Auth.user)
    const navigate = useNavigate(); // Initialize the navigate function

    const handleLogout = () => {
        setTimeout(() => {
            // navigate('/', { replace: true });
            navigate('/');
            toast.success("Logout successfully")
        }, 100);
        setTimeout(() => {
            dispatch(setLogout());
        }, 600);
    }

    return (
        <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
            <MenuHandler>
                <Button
                    variant="text"
                    color="blue-gray"
                    className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto">
                    <Avatar
                        variant="circular"
                        size="sm"
                        alt="tania andrew"
                        className="border border-gray-900 p-0.5"
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80" />
                    <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""}`} />
                </Button>
            </MenuHandler>
            <MenuList className="p-1">
                {profileMenuItems.map(({ label, url, icon }, key) => {
                    const isLastItem = key === profileMenuItems.length - 1;
                    return (
                        <MenuItem
                            key={label}
                            onClick={closeMenu}
                            className={`flex items-center gap-2 rounded ${isLastItem
                                ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10" : ""}`}>
                            {React.createElement(icon, {
                                className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                                strokeWidth: 2,
                            })}
                            <Link to={url}>
                                <span
                                    as="span"
                                    variant="small"
                                    className="font-normal"
                                    color={isLastItem ? "red" : "inherit"}>
                                    {label}
                                </span>
                            </Link>
                        </MenuItem>
                    );
                })}
                {!user && user.role !== 'user' ?
                    <Button size="sm" variant="text">
                        <a href='/login'>Log In</a>
                    </Button>
                    :
                    <Button size="sm" variant="text" onClick={handleLogout}>
                        Log Out
                    </Button>
                }
            </MenuList>
        </Menu >
    );
}

// nav list component
const navListItems = [
    {
        label: "Menu",
        icon: HomeIcon,
        href: "/"
    },
    {
        label: "Dashboard",
        // icon: UserCircleIcon,
        icon: ChartPieIcon,
        href: "/member"
    },
    {
        label: "Extra",
        icon: CubeTransparentIcon,
        href: ""
    },
    // {
    //     label: "Docs",
    //     icon: CodeBracketSquareIcon,
    //     href: ""
    // },
];

function NavList() {
    return (
        <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
            {navListItems.map(({ label, href, icon }, index) => (
                <Link to={href} key={index} className="flex items-center gap-2 lg:rounded-full">
                    {React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}
                    <Typography
                        variant="small"
                        color="gray"
                        className="font-medium text-blue-gray-500">
                        {label}
                    </Typography>
                </Link>
            ))}
        </ul>
    );
}

export default function NavbarCustom2() {
    const [isNavOpen, setIsNavOpen] = React.useState(false);

    const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setIsNavOpen(false),
        );
    }, []);

    return (
        <Navbar className="mx-auto lg:pl-6 rounded-none min-w-[100vw]">

            <div className="max-w-screen-xl relative mx-auto flex items-center justify-between text-blue-gray-900">
                <Link to="/" className="relative flex items-center space-x-3 rtl:space-x-reverse z-[11] shadow-lg rounded-xl" >
                    <span id="fmb_Logo2" className="w-36">
                        <img src={fmbLogo} alt="fmb_Logo" className="w-full" />
                    </span>
                </Link>
                <div className="hidden lg:block ml-auto">
                    <NavList />
                </div>

                <IconButton
                    size="sm"
                    color="blue-gray"
                    variant="text"
                    onClick={toggleIsNavOpen}
                    className="ml-auto mr-2 lg:hidden"
                >
                    <Bars2Icon className="h-6 w-6" />
                </IconButton>

                <ProfileMenu />
            </div>
            <MobileNav open={isNavOpen} className="overflow-scroll">
                <NavList />
            </MobileNav>
        </Navbar >
    );
}