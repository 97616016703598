import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    TabPanel,
    Tabs,
    Typography,
} from "@material-tailwind/react";
import React, { memo, useEffect, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { setCoordinators } from "../../../app/slices/authSlice2";
import "../../../Routes/Register.css";
import { getRequest, postRequest } from "../../../service/ApiEndpoint";

function AdminAddArea() {
    const dispatch = useDispatch();
    const selector = useSelector(state => state.Auth.coordinators);

    // Member Login State
    const [form, setForm] = useState({
        name: "",
        coordinatorId: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!form.name) return toast.error("First enter area name");
        if (!form.coordinatorId) return toast.error("Select Area Coordinator");

        try {
            // Sending Data
            const request = await postRequest('/area', {
                name: form.name.toLowerCase(),
                coordinatorId: Number(form.coordinatorId),
            });

            // SUCCESS
            const response = request.data;
            if (response) {
                toast.success("Area created successfully");
                // Clear Form
                setForm({
                    name: "",
                });
            }
            window.location.href = "/admin/area_master";

            // Catch Error
        } catch (error) {
            // toast.error(error?.message);
            console.log(error);
        }
    };

    useEffect(() => {
        // GET All Areas
        const getAllUsers = async () => {
            try {
                // Request
                const request = await getRequest('user?role=coordinator', {});
                const response = request.data;
                dispatch(setCoordinators(response));

                // Catch Error
            } catch (error) {
                // toast.error(error?.message);
                console.log(error);
            }
        };
        getAllUsers();
    }, [dispatch]);

    function capitalizeWords(str) {
        return str
            .split(' ')                // Split the string into an array of words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ');                // Join the array back into a single string
    }

    return (
        <div className="cus-container mx-auto px-2 py-8 flex justify-center">
            <Card className="w-full sm:max-w-[24rem]">
                <CardHeader
                    color="gray"
                    floated={false}
                    shadow={false}
                    className="m-0 grid place-items-center px-4 py-8 text-center"
                >
                    <div className="mb-4 h-20 p-6 text-white">
                        <>
                            <FaUserPlus className="h-10 w-10 text-white mx-auto" />
                            <Typography variant="h5" color="white" className="mx-auto">
                                Create New Area
                            </Typography>
                        </>
                    </div>
                </CardHeader>
                <CardBody className="mb-4">
                    <Tabs className="overflow-visible">
                        <div className="registerForm">
                            <TabPanel className="p-0">
                                <Card className="mx-auto w-full max-w-[24rem] h-[330px]" shadow={false}>
                                    <form onSubmit={handleSubmit}>
                                        <CardBody className="flex flex-col gap-4 pt-2">
                                            <Typography className="text-xs mb-1 font-normal" variant="paragraph" color="gray">
                                                Entered details must be unique
                                            </Typography>
                                            <Typography className="-mb-2" variant="h6">
                                                Area Name
                                            </Typography>
                                            <Input label="Enter your area name" size="lg" value={form.name} name="name" type="text" onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} />
                                            <Typography className="-mb-2" variant="h6">
                                                Select Area Coordinator
                                            </Typography>
                                            <select onChange={(e) => setForm({ ...form, coordinatorId: e.target.value })} className="text-blue-gray-700 text-sm font-normal py-3 px-3 bg-transparent border border-blue-gray-200 rounded-md w-full h-full"
                                                defaultValue={"Select Coordinator"}>
                                                <option disabled>Select Coordinator</option>
                                                {selector?.map(el => <option value={el.id} key={el.id}>{capitalizeWords(el?.name)}</option>
                                                )}
                                            </select>
                                        </CardBody>
                                        <CardFooter className="pt-0">
                                            <Button className="bg-gray-500" type="submit" fullWidth>
                                                Submit
                                            </Button>
                                        </CardFooter>
                                    </form>
                                </Card>
                            </TabPanel>
                        </div>
                    </Tabs>
                </CardBody>
            </Card>
        </div>
    );
};

export default memo(AdminAddArea);