import { Button, Dialog, DialogFooter } from '@material-tailwind/react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';
import { MdDeleteOutline } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { setAreas, setMembers } from '../../../app/slices/authSlice2.js';
import ButtonAnimated from '../../../components/comman/Button/AnimatedButton/ButtonAnimated';
import ToggleButtonAnimated from '../../../components/comman/Button/AnimatedButton/ToggleButtonAnimated';
import LabelComponent from '../../../components/comman/Label/LabelComponent';
import AdminMemberEditModal from '../../../components/ModalForms/AdminMemberEditModal.jsx';
import { capitalizeWords } from '../../../extraStuff.js';
import { deleteReq, getRequest } from '../../../service/ApiEndpoint';
import "./AdminMembers.css";

function AdminMembers() {
    const tableHead = ["S.No.", "Full Name", "Email", "Contact", "ITS", "Area", "Note", "Action"]
    const [fetchData, setFetchData] = useState([]);
    const [fetchDay, setFetchDay] = useState([]);
    const [fetchArea, setFetchArea] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);

    const [memberModal, setMemberModal] = useState(false)
    const [passData, setPassData] = useState({})

    useEffect(() => {
        // GET All Members
        const getAllUsers = async () => {
            try {
                // Request
                const request = await getRequest('user?role=user', {})
                const requestArea = await getRequest('/area', {})

                // User Data
                const response = request?.data;
                // Area Data
                const responseArea = requestArea?.data;

                setFetchData(response?.users);
                setFetchDay(response?.userDay);
                setFetchArea(responseArea);
                console.log(response?.userDay);

                dispatch(setMembers(response?.users));
                dispatch(setAreas(responseArea));

                // Catch Error
            } catch (error) {
                // toast.error(error?.message)
                console.log(error);
            }
        }
        getAllUsers();
    }, [dispatch])

    const handleDelete = useCallback(async (item) => {
        try {
            const response = await deleteReq(`/user/${item}`);
            if (response) {
                const response = await getRequest('user?role=user', {});
                setFetchData(response.data);
                dispatch(setMembers(response.data));
                toast.success("Member Deleted Successfully");
            }
        } catch (error) {
            console.log(error);
        }
    }, [dispatch]); // Added dispatch and getRequest as dependencies

    const handleRegister = () => {
        navigate("/admin/register");
    }

    // CONFIRM BEFORE DELETE AREA
    const handleOpenConfirm = (id) => {
        setDeleteId(id);
        setIsConfirmOpen(true);
    };

    const handleDeleteConfirmed = () => {
        handleDelete(deleteId);
        setIsConfirmOpen(false);
    };

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    return (
        <div className='max-w-screen-xl mt-14 mx-auto bg-white py-2 rounded-xl shadow-xl'>
            {memberModal &&
                <AdminMemberEditModal memberModal={memberModal} passData={passData} setMemberModal={setMemberModal} setFetchData={setFetchData} />
            }
            <LabelComponent labelNameClass={'text-3xl font-medium text-pri mx-4'} labelName={'Members'} />

            <div className='motif-central'>&nbsp;</div>
            <ButtonAnimated btnIcon={<IoPersonAddSharp className='text-xl' />} btnName={'Add Member'} btnOnClick={handleRegister} />

            {/* {!coordinators.length > 0 && (<div className='m-4 font-medium text-red-600'> Add Coordinator!</div>)} */}

            <div className="relative overflow-x-auto shadow-md rounded-lg my-4 border-2 border-sec mx-2 bg-white">
                {/* TABLE */}
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {/* TABLE HEAD */}
                    <thead className="text-xs uppercase bg-sec text-sec">
                        <tr>
                            {tableHead.map((headItem, idx) => <th scope="col" className="pl-2 px-1 py-3 font-semibold" key={idx}>
                                {headItem}
                            </th>)}
                        </tr>
                    </thead>
                    {/* TABLE BODY */}
                    <tbody>
                        {fetchData?.map((el, idx) =>
                            <tr className='hover:bg-[--table-bg-hoverBlue]' key={el?.id}>
                                <th scope="row" className="px-1 py-1 font-medium base-text whitespace-nowrap dark:text-gray-900 mx-2 pl-2">
                                    {idx + 1}
                                </th>
                                <td className="pl-2 py-1 base-text min-w-[150px]">
                                    {capitalizeWords(el?.name)}
                                    {/* {el?.name.charAt(0).toUpperCase()} */}
                                </td>
                                <td className="pl-2 py-1 base-text min-w-[150px]">
                                    {el?.email}
                                </td>
                                <td className="pl-2 py-1 base-text min-w-[105px]">
                                    {el?.phone}
                                </td>
                                <td className="pl-2 py-1 base-text min-w-[80px]">
                                    {el?.itsId}
                                </td>
                                <td className="pl-2 py-1 base-text min-w-[150px]">
                                    {capitalizeWords(fetchArea?.find(item => item.id === el?.areaId).name)}
                                </td>
                                <td className="pl-2 py-1 base-text min-w-[150px]">
                                    {el?.note || 'No Comments'}
                                </td>
                                {/* <td className="pl-2 py-1 base-text relative">
                                    <details
                                        open={openIndex === idx}
                                        onMouseEnter={() => setOpenIndex(idx)}
                                        onMouseLeave={() => setOpenIndex(null)}
                                        className="details-hover">
                                        <summary className='cursor-pointer text-green-500'>Show</summary>
                                        <div className='w-[250px] absolute -translate-y-[75%] -translate-x-[100%] z-10 bg-black border border-gray-500 rounded-lg p-2 text-white flex items-start flex-wrap flex-row gap-1'>
                                            {fetchDay?.map((item, idx) => item.userId === el.id && item.active === 1 &&
                                                <span key={idx} value={item.day} className='mx-1 bg-green-500 text-white rounded-md px-1'>{days[item.day]}</span>
                                            )}
                                        </div>
                                    </details>
                                </td> */}
                                <td className="py-2 flex items-center">
                                    <ToggleButtonAnimated btnIcon={
                                        <TbEdit className='text-green-600 cursor-pointer text-xl' />
                                    } onBtnClick={() => { setPassData(el); setMemberModal(!memberModal) }} />
                                    <ToggleButtonAnimated btnIcon={
                                        < MdDeleteOutline className='text-red-600 cursor-pointer text-xl' />
                                    } onBtnClick={() => handleOpenConfirm(el?.id)} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <LabelComponent labelName={`Displaying ${fetchData.length} records`} labelNameClass={'m-2 text-xs base-text'} />


            {/* Confirmation Dailog Modal */}
            <Dialog open={isConfirmOpen} handler={() => setIsConfirmOpen(!isConfirmOpen)}>
                <h1 className='font-black text-gray-800 text-xl m-4'>Confirm Deletion</h1>
                <p className='font-normal text-gray-600 text-md m-4'>
                    Are you sure you want to delete the Member?
                </p>
                <DialogFooter className='flex gap-2'>
                    <Button variant="text" color="red" onClick={() => setIsConfirmOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleDeleteConfirmed}>
                        Confirm
                    </Button>
                </DialogFooter>
            </Dialog>
        </div >
    )
}

export default memo(AdminMembers)