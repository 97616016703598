import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import thaaliIcon from "../../assets/images/thaali-icon.png";
import UserDashboard from '../../components/UserDashboard/UserDashboard';
import { getRequest } from '../../service/ApiEndpoint';

function MemberProfilePage() {
    const loggedUser = useSelector((state) => state.Auth.user)
    const [fetchData, setFetchData] = useState({
        userProfile: [],
        userDays: []
    })

    useEffect(() => {
        // GET All Users
        const getAllUsers = async () => {
            try {
                // Request
                const request = await getRequest(`user?role=user/${loggedUser.id}`, {})
                const response = request.data;
                // Update state with both userDays and userProfile in one call
                setFetchData({
                    userProfile: response?.userData || [],
                    userDays: Array.isArray(response?.daysData?.days) ? response.daysData.days : [response.daysData.days]
                });

                // Catch Error
            } catch (error) {
                // toast.error(error?.message)
                console.log(error);
            }
        }
        getAllUsers();

    }, [loggedUser, setFetchData])

    const user = {
        name: fetchData.userProfile.name,
        phone: fetchData.userProfile.phone,
        email: fetchData.userProfile.email,
        profileImage: thaaliIcon,
        smsAlerts: false
    };

    const bills = [
        { name: 'Niyaz ', due: false },
        { name: 'Thaali ', due: true },
    ];
    return (
        <div className='max-w-screen-xl mx-auto'>
            <UserDashboard user={user} defaultDays={fetchData.userDays} bills={bills} />
        </div>
    )
}

export default memo(MemberProfilePage)